.container {
    background-color: #fff;
}

.datePickerContainer>div {
    width: 100%;
}

.isLoading{
    pointer-events: none;
}



.datePickerContainer label + div>div {
    width: 100%;
}


.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f9fa;
    padding: 10px;
}

.marginVertical15 {
    margin: 15px 0px;
}

.content {
    padding: 10px 30px;
}

.footer {
    display: flex;
    display: flex;
    justify-content: end;
    padding: 10px;
}

.uniqueId{
    font-size: 10px;
}