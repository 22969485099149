/* body,
html {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
} */
.inv-desktop-wrapper {
  display: block;
  float: left;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .inv-desktop-wrapper {
    display: none;
  }
}
.inv-mobile-wrapper-main {
  display: block;
}
@media screen and (min-width: 601px) {
  .inv-mobile-wrapper-main {
    display: none;
  }
}
.inv-mobile-wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #eef0f2;
}
.inv-center {
  text-align: center;
}
.inv-right {
  text-align: right;
}
.inv-inventory-head {
  font-weight: 800;
  color: #202020;
  font-size: 24px;
}

.inv-inventory-head .top_btn_group {
  margin-top: 20px;
}

.inv-inventory-head .top_btn_group .button {
  margin: 0 0px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
}

.inv-inventory-head .top_btn_group .button {
  margin-bottom: 8px;
}

.inv-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.inv-inline-row {
  display: block;
  float: left;
  width: 100%;
}
.inv-inline-row-padding {
  padding: 0 5px;
}
.inv-page-head {
  float: left;
  width: 100%;
  border-bottom: solid 1px #d8d8d8;
  padding: 4px 0;
}
.inv-crew-search-wrapper {
  display: block;
  padding: 10px 0;
}
.inv-crew-search-wrapper .inv-input-wrapper {
  position: relative;
}
.inv-crew-search-wrapper .inv-input-wrapper input[type="text"],
.inv-crew-search-wrapper .inv-input-wrapper select {
  display: block;
  width: 100%;
  height: 46px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  padding: 0 23px;
}
.inv-head-select-box {
  padding: 15px 0;
  font-size: 18px;
}
.inv-head-select-box .inv-label-gray {
  color: #696969;
}
.inv-head-select-box .inv-label-green {
  color: #00703C;
  font-weight: 600;
}
.inv-result-set {
  background-color: #fff;
  padding: 0 15px 0;
  margin-bottom: 8px;
  border-bottom: solid 1px #e3e3e3;
}
.inv-result-set .inv-result-set-head {
  margin: 0 -15px;
  padding: 18px 35px 13px;
  border-bottom: solid 1px #f0f0f0;
  overflow-y: auto;
}
.inv-result-set .inv-title-main {
  color: #00703C
;
  font-size: 18px;
  font-weight: 700;
}
.inv-result-set .inv-result-content {
  margin: 0 -15px;
  padding: 24px 0 30px 35px;
  overflow-x: scroll;
  position: relative;
  z-index: 1;
}
.inv-result-set .inv-title-sub {
  color: #696969;
  font-size: 18px;
}
.inv-result-set .inv-result-collection {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}
.inv-result-set .inv-result-collection li {
  max-width: 250px;
  min-width: 165px;
  display: flex;
  flex-direction: row;
}
.inv-result-set .inv-result-collection li .inv-single-result {
  display: flex;
  flex-direction: row;
}
.inv-result-set .inv-result-collection li .inv-single-result .inv-result-thumb {
  display: inline-block;
  float: left;
  display: flex;
  flex: auto;
  max-width: 50px;
  max-height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
}
.inv-result-set
  .inv-result-collection
  li
  .inv-single-result
  .inv-result-thumb
  img {
  width: 100%;
  height: 100%;
}
.inv-result-set
  .inv-result-collection
  li
  .inv-single-result
  .inv-result-detail-wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 0 10px;
  justify-content: center;
}
.inv-result-set
  .inv-result-collection
  li
  .inv-single-result
  .inv-result-category,
.inv-result-set .inv-result-collection li .inv-single-result .inv-result-name {
  font-size: 14px;
  color: #696969;
}
.inv-side-menu-overlay {
  display: block;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: none;
}
.inv-sidemenu-box {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 90%;
  max-width: 340px;
  background-color: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  -webkit-transform: translateX(340px);
  transform: translateX(340px);
  transition: all ease 0.3s;
}
.inv-sidemenu-box .inv-header-controls {
  display: flex;
  flex: auto;
  max-height: 60px;
  min-height: 60px;
  flex-direction: row;
  justify-content: space-between;
}
.inv-sidemenu-box .inv-header-controls .inv-icon-icons-button {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.inv-sidemenu-box .inv-header-controls .inv-icon-icons-button:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}
.inv-sidemenu-box .inv-header-controls .inv-icon-icons-button img {
  width: 22px;
  height: 22px;
}
.inv-sidemenu-box .inv-header-contents {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
}
.inv-sidemenu-box .inv-item-info-main {
  display: flex;
  flex-direction: row;
  padding: 20px 20px 33px;
  border-bottom: solid 1px #dfdfdf;
  box-shadow: 0 27px 26px -10px #fff;
  position: relative;
  z-index: 8;
}
.inv-sidemenu-box .inv-sidebar-item-thumb {
  display: flex;
  width: 80px;
  height: 80px;
  min-width: 80px;
}
.inv-sidemenu-box .inv-sidebar-item-thumb img {
  width: 100%;
  height: 100%;
}
.inv-sidemenu-box .inv-item-content {
  font-size: 14px;
  padding: 0 15px;
}
.inv-sidemenu-box .inv-label-bold {
  font-weight: 600;
}
.inv-sidemenu-box .inv-item-body-banner {
  overflow-y: scroll;
}
.inv-sidemenu-box .inv-item-body-banner .inv-actions-buttons {
  display: block;
  margin: 14px 0 0 0;
  padding: 20px;
}
.inv-sidemenu-box .inv-item-body-banner ul {
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: block;
  float: left;
  width: 100%;
  margin: 17px 0;
  padding: 17px 0;
  font-size: 14px;
  color: #696969;
}
.inv-sidemenu-box .inv-item-body-banner ul li {
  padding: 17px 24px;
  display: block;
  width: 100%;
  float: left;
}
.inv-sidemenu-box .inv-item-body-banner ul li:first-child {
  padding-top: 0;
}
body.inv-sidebar-opened {
  overflow: hidden;
}
.inv-sidebar-opened .inv-side-menu-overlay {
  display: block;
}
.inv-sidebar-opened .inv-sidemenu-box {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.inv-assign-section {
  background-color: #fff;
  margin-top: 40px;
}
.inv-assign-section .inv-selected-thumb {
  padding: 0 35px;
  display: flex;
  padding-bottom: 33px;
}
.inv-assign-section .inv-selected-thumb .inv-thumb-image {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  display: flex;
}
.inv-assign-section .inv-selected-thumb .inv-thumb-image img {
  width: 100%;
  height: 100%;
}
.inv-assign-section .inv-selected-thumb .inv-thumb-details {
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
}
.inv-assign-section .inv-selected-thumb .inv-thumb-details .inv-gray {
  color: #696969;
  margin-right: 4px;
  font-weight: 500;
}
.inv-assign-section .inv-selected-thumb .inv-thumb-details .inv-green {
  color: #00703C
;
  font-weight: 400;
}
.inv-selected-head {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  padding: 0 35px;
}
.inv-selected-head .inv-cncl {
  color: #a3a3a3;
}
.inv-selected-head .inv-bck {
  color: #00703C
;
}
.inv-selected-head .inv-bck,
.inv-selected-head .inv-cncl {
  padding: 15px 0;
  width: 60px;
}
.inv-selected-head .inv-bck.inv-active,
.inv-selected-head .inv-bck:active,
.inv-selected-head .inv-bck:focus,
.inv-selected-head .inv-bck:hover,
.inv-selected-head .inv-cncl.inv-active,
.inv-selected-head .inv-cncl:active,
.inv-selected-head .inv-cncl:focus,
.inv-selected-head .inv-cncl:hover {
  opacity: 0.8;
}
.inv-crew-list .inv-gray-color {
  color: #696969;
  font-weight: 500;
}
.inv-crew-list .inv-green-color {
  color: #00703C
;
  font-weight: 700;
}
.inv-crew-list .inv-crew-list-head {
  background-color: #f6f6f6;
  padding: 32px 35px;
  font-size: 18px;
  border: solid 1px #d6d6d6;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.inv-crew-list .inv-crew-item-wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: solid 7px #f6f6f6;
  border-top: solid 1px #e3e3e3;
}
.inv-crew-list .inv-crew-item-wrapper .inv-top-section {
  display: flex;
  width: 100%;
  padding: 25px 35px 20px;
}
.inv-crew-list .inv-crew-item-wrapper .inv-crew-action-bar-bottom {
  border-top: solid 1px #e3e3e3;
  text-align: center;
  padding: 16px 0;
  border-bottom: solid 1px #e3e3e3;
}
.inv-crew-list
  .inv-crew-item-wrapper
  .inv-crew-action-bar-bottom
  .inv-btn-small-actions:last-child {
  margin-right: 0;
}
.inv-crew-list .inv-crew-item-wrapper .inv-crew-item-thumb {
  width: 50px;
  height: 50px;
  display: flex;
  max-width: 50px;
  min-width: 50px;
  min-height: 50px;
  max-height: 50px;
}
.inv-crew-list .inv-crew-item-wrapper .inv-crew-item-thumb img {
  width: 100%;
  height: 100%;
}
.inv-crew-list .inv-item-details-inner {
  font-size: 18px;
}
.inv-crew-list .inv-item-details-inner .inv-green {
  color: #00703C
;
  font-weight: 700;
}
.inv-crew-list .inv-item-details-inner .inv-gray {
  color: #696969;
  font-weight: 400;
}
.inv-crew-list .inv-item-details {
  font-size: 18px;
  padding: 0 15px;
}
.inv-button {
  height: 32px;
  width: 160px;
  height: 30px;
  text-align: center;
  display: inline-block;
  border: none;
  color: #fff;
  max-width: 100%;
}
.inv-button.inv-button-primary {
  font-weight: 700;
  background-color: #00703C;
  border-radius: 3px;
}
.inv-button.inv-button-primary:hover{
  background-color: #005C31;
}

.inv-button.inv-button-primary.inv-add-inventory {
  margin: 10px 0;
}

.inv-action-button .mobile-print-loader{
  position: absolute;
  left: calc(50% - 10px);
  bottom: -35px;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.inv-action-button,
.inv-btn-small-actions {
  border: solid 1px #00703C
;
  border-radius: 3px;
  height: 30px;
  background: #fff;
  color: #00703C
;
  width: 100%;
  margin-bottom: 18px;
  font-size: 16px;
  position: relative;
}
.inv-action-button.inv-active,
.inv-action-button:active,
.inv-action-button:focus,
.inv-action-button:hover,
.inv-btn-small-actions.inv-active,
.inv-btn-small-actions:active,
.inv-btn-small-actions:focus,
.inv-btn-small-actions:hover {
  background-color: #00703C
;
  color: #fff;
  outline: 0;
}
.inv-btn-small-actions {
  width: initial;
  display: inline-block;
  margin-bottom: 0;
  min-width: 110px;
  border-radius: 8px;
  margin-right: 20px;
}


.inventoryMobileExcelModal .ReactModal__Content.ReactModal__Content--after-open{
  width: 90%;
}