
// mobile responsive designs only.
.right_sidebar .user-docs {
    @media screen and (max-width: 768px){
        padding-top: 60px;
    }
    &.user-onboarding{
        @media screen and (max-width: 768px){
            padding-top: 0px;
        }
    }
   
}
body.document-active .right_sidebar .user-docs .onboarding-docs .doc_info .doc_info-form .info-form .inputs-col p{
    opacity: 1;
}
.user-docs{
    padding-top: 60px;
    &.user-onboarding{
        padding-top: 20px;
    }
    .doc_info {
        .doc_info-rh {
            width: calc(100% - 252px);

            .doc_info-form{
                .sign_canvas{
                    .has_signed{
                        & + .sign_btn-group{
                            button{
                                background: #3f7e3a !important;
                            }
                        }
                    }
                }
            }

            .rehire{
                .emergency-contact-select{
                    flex-direction: column-reverse !important;
                }
            }
            
        }
    }
}

.admin-user{
    .user-docs{
        .docs_breadcrumbs{
            margin-top: 0;
        }
        .page_title{
            @media screen and (max-width: 767px){
                padding-bottom: 15px;
            }
        }
        &.user-docs{
            padding-top: 60px;
            @media screen and (max-width: 767px){
                padding-top: 45px;
            }

            &.user-onboarding{
                padding-top: 20px;
            }
            &.mobile-mode{
                padding-top: 0px !important;
            }
            
        }
    }
}

.giss-wage-rate, .giss_emp{
    .inputs-head, strong{
        @media screen and (min-width: 769px){
            font-family: "proxima_nova_altbold", Georgia, sans-serif;
        }
        
    }
}

.row.form-row.show-mob{
padding-top: 15px !important;
.inputs-col{
    margin-bottom: 10px !important;
}
@media screen and (min-width: 769px){
    display: none !important;
}
}

.doc_info-form.paydates{
.payments-note{
    .col{
        @media screen and (min-width: 769px){
            flex-direction: column;
        }
    }
}
}

.accessories-total-text{
    @media screen and (min-width: 769px){
        display: none !important;
    }
}
.accessories-total-text{
    padding: 10px;
}


@media screen and (max-width: 768px){
.admin-user{
    .user-docs{
        padding-top: 25px;
        .onboarding-docs{
            min-height: calc(100vh - 100px);
        }
    }
}
.user-docs{
    &.mobile-mode{
        padding-top: 0px;
        padding-bottom: 0px;
        max-width: 100vw;
        
        .sidebar-user-mobile{
            background-color: #eef0f2;
            padding-bottom: 15px;
        }
        
        .sign_modal{
            .sign_modal-content{
                p{
                    opacity: 1 !important;
                }
            }
        }
        textarea{
            &::-webkit-input-placeholder { 
                white-space: pre-line !important;  
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                
                
              }
              &::-moz-placeholder { 
                   white-space: pre-line !important;  
                   text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                
              }
              &:-ms-input-placeholder { 
                  white-space: pre-line !important;  
                  text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                
              }
              &:-moz-placeholder { 
                   white-space: pre-line !important;  
                   text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                
              }
            }

        .onboarding-docs {
            .data_table.dates{
                .item{
                    &:first-child{
                        max-width: 70px;
                    }
                }

                .data_table-info{
                    .item{
                        &:first-child{
                            text-align: center;
                        }
                    }
                    &:last-child{
                        margin-bottom: 28px;
                    }
                }
            } 
            .data_table{
                &.holidays{
                    margin-top: 5px;
                    margin-bottom: 30px;
                    
                }
                
            }
            .doc_info {
                .doc_info-rh {
                    width: 100%;
                    .doc_info-form {
                        padding-top: 20px;
                        padding-left: 15px;
                        padding-right: 15px;
                        max-width: 100vw;
                        overflow-x: hidden;
                        p,.inputs-head,ol {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                        ol{
                            margin-left: 20px;
                        }

                        .col-md-12{
                            padding-left: 0px;
                            padding-right: 0px;
                        }

                        

                        /*form specific*/
                        .rehire{
                            .col-md-4{
                                padding-left: 0px;
                                padding-right: 0px;
                            }

                            input#phone{
                                margin-bottom: 15px;
                            }

                            .notify{
                                font-size: 14px !important;
                                line-height: 24px;
                            }
                            span.asterix{
                                display: block;
                            }

                            .inputs-head{
                                strong{
                                    font-weight: bold;
                                    font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                }
                            }

                            .emergency-contact-select{
                                flex-direction: column-reverse !important;
                            }
                        }
                        .mpn{
                           
                            .col-md-12{
                                padding-left: 0px;
                                padding-right: 0px;
                            }
                        }

                        .giss_emp{
                            .check_radio_group.agreement_checks label{
                                margin-right: 0;
                                strong{
                                    display: inline-block;
                                }
                            } 
                        }

                        &.form-i9{
                            .col-md-12{
                                padding-left: 0;
                                padding-right: 0;
                                strong{
                                    font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                }
                            }
                        }
                        .col {

                            &.emp-sizes{
                                flex-direction: row;
                                flex-wrap: wrap;
                                .inputs-col{
                                    flex: 0 0 calc(50% - 6px);
                                    &:nth-child(odd){
                                        margin-right: 12px;
                                    }
                                }
                            }
                            &.li-col{
                                padding: 0;
                                input[type="checkbox"]{
                                    margin-right: 20px;
                                    ~ .checkbox_checkbox{
                                        left: -10px;
                                    }
                                    
                                }
                            }
                            &.f_passport_check{
                                .inputs-col-g{
                                    padding-left: 30px !important;
                                }
                                .inputs-col:last-child{
                                    padding-left: 0px;
                                }
                                .inputs-col{
                                    margin-bottom: 10px;
                                    & + .inputs-col{
                                        margin-bottom: 20px;
                                        padding-left: 30px;
                                        label{
                                            margin-left: 0px !important;
                                        }
                                    }
                                }
                            }
                            p,.inputs-head {
                                padding-left: 0px;
                                padding-right: 0px;
                            }
                            p.checkb_detail{
                                opacity: 1 !important;
                            }
                            .inputs-col {
                                &.mb-sm-0{
                                    margin-bottom: 0px;
                                }
                                .inputs-head {
                                    font-weight: bold;
                                    font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                }

                                
    
                                .agreement_radio{
                                    label{
                                       
                                    }
                                    strong{ 
                                        display: block;
                                        font-weight: bold;
                                        font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                    }
                                }
    
                                .check_radio_group.agreement_checks label{
                                    strong{
                                        font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                        font-weight: bold;
                                    }
                                    width: 100%;
                                    
                                    max-width: 100%;
                                }
    
                                .check_radio_group{
                                    &.agreement_checks{
                                        ul.checkb_lists{
                                            li{
                                                span{
                                                    font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                                    font-weight: bold;
                                                }
                                            } 
                                        } 
                                    }
                                    &.check_disclaimer{
                                        padding-left: 15px;
                                        padding-right: 15px;
                                    }

                                    &.del_opt_radio{
                                        label{
                                            width: 100% !important;
                                        }
                                    }
                                }
                            }
    
                            .disclaimers{
                                padding-left: 20px;
                                padding-right: 20px;
                                .inputs-head{
                                    font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                    text-transform: none;
                                }
                                &.dis-notes{
                                                                       
                                    .inputs-head{
                                        padding-right: 20px;
                                        button.toggler_btn {
                                            padding-right: 3px;
                                            right: -10px !important;
                                            width: 30px;
                                            height: 30px;
                                            background-position: center;
                                            top: -5px;
                                        }
                                        
                                    }
                                }
                            }
                        }

                        .info-form{
                            &.merchandise{
                                p{
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }

                                .inputs-col input[type="text"],
                                .inputs-col input[type="number"],
                                .inputs-col select{
                                    padding-left: 15px !important;
                                    padding-right: 15px !important;
                                    text-align: left !important;
                                }

                                .accessories-total-text{
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    padding-top: 15px;
                                    padding-bottom: 15px;
                                    background-color: #f2f2f2;
                                    border-top: 1px solid #dadada;
                                    p{
                                        line-height: 24px;
                                        padding-top: 20px;
                                        margin-bottom: 0px !important;
                                        font-weight: bold;
                                        font-style: italic;
                                        font-weight: bolder;
                                        font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                        padding-top: 0px;
                                    }
                                    
                                }
                            }
                        } 
    
                        .action_buttons{
                            padding-top: 20px;
                            padding-bottom: 20px;
                           
                            
                            
                            &:before{
                                left: -15px;
                                right: -15px;
                            }

                            @media screen and (max-width: 440px){
                                flex-direction: column;
                                align-items: center;
                                button{
                                    width: 100%;;
                                   // max-width: 100px;
                                    margin-top: 10px;
                                }
                                .pagination-number{
                                    margin-top: 10px;
                                    order: 5;
                                }
                            }
                        }
    
                    }
    
                    .doc-title{
                        h3{
                            max-width: 100% !important;
                            font-size: 15px;
                        }
                        .close-button{
                            flex-basis: 30px;
                        }
                        height: auto !important;
                        min-height: 48px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    
                    } 

                    &.form-w4{

                        .inputs-head{
                            &.font-mob{
                                font-size: 16px !important;
                            }

                            ul{
                                li{
                                    font-weight: normal !important;
                                }
                            }
                        }

                        .row{
                            &.form-row{
                                &.no_border{
                                    &.no-padding{
                                        padding-top: 0px;
                                        ul{
                                            li{
                                                margin-top: 0px !important;
                                                & + li{
                                                    margin-top: 20px !important;
                                                }
                                            }
                                        }

                                        .inputs-col{
                                            margin-bottom: 10px;
                                        }
                                        &.pt-2{
                                            padding-top: 20px !important
                                        }
                                    }
                                    //padding-top: 0px !important;
                                    .inputs-col{
                                       // margin-bottom: 0px;
                                    }

                                    &.no_padding{
                                        ul{
                                            li{
                                                margin-top: 0px !important;
                                                & + li{
                                                    margin-top: 20px !important;
                                                }
                                            }
                                        }

                                        
                                    }

                                    
                                }

                                &.f-row{
                                    
                                    .disclaimers.dis-notes{
                                        margin-top: 0px !important;
                                    }
                                    
                                }

                                &.pb-0{
                                    padding-bottom: 0px !important;
                                }
                            }

                             .mbp-1{
                                margin-bottom: 10px !important;
                             }
                             .mbp-0{
                                margin-bottom: 0px !important;
                             }
                        }

                        p, li{
                            color: #000000;
                            opacity: 1 !important;
                            
                            b{
                                font-family: "proxima_nova_altbold", Georgia, sans-serif;
                            }
                        }
                        li{
                            font-family: "Montserrat", sans-serif;
                        }
                        strong{
                            font-weight: bold;
                            font-family: "proxima_nova_altbold", Georgia, sans-serif;
                        }
                        .col-md-12{
                            h3{
                              padding-left: 15px;
                              padding-right: 15px;  
                            }

                            ul{
                                padding-left: 30px;
                                padding-right: 15px;
                                p{
                                    padding-left: 0px;
                                }
                            }

                            p{
                                opacity: 1;
                                &.w-list{
                                    opacity: 1;
                                    b{
                                        font-weight: bold;
                                        font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                    }
                                }
                            }
                        }
                    }
                }
                .filter_form {
                    .doc_info-form {
                        .col {
                            .inputs-col {
                                .inputs-head {
                                    font-weight: bold;
                                    font-family: "proxima_nova_altbold", Georgia, sans-serif;
                                }
                            }
                        }

                        .action_buttons{
                            button{
                                min-width: 50px;
                            }
                        } 
                    }
                }
            }
    
    
            /*need to add extra class in pay dates*/
            .doc_info-form.paydates{
                
                .instructions{
                    border-top: 0px !important;
                    p.text-center, h3.text-center{
                        text-align: left !important;
                    }
                    h3.text-center{
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
                padding: 0;

                
            }
            .paydates .data_table{
                padding: 0;
                .data_table-head {
                    .item{
                        font-family: "proxima_nova_altbold", Georgia, sans-serif;
                    }
                }
            }
        }
    
        .giss_rates{
            td{
                display: table-cell;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .giss_notes{
            color: #000000;
            line-height: 24px !important;
            margin-bottom: 10px !important;
        }
    
        .delivery_opt {
            .inputs-col {
                .check_radio_item {
                    input[type="radio"] {
                        label {
                            display: none;
                        }
                        
                    }
                }
            }
        }

    }
} 


}

@supports (-webkit-touch-callout: none) {
    @media screen and (max-width: 768px){
        body.document-active .right_sidebar .outerMostContainer.ios .user-docs .onboarding-docs .doc_info .doc_info-form .info-form .action_buttons{
            margin-bottom: 30px !important
        } 

    }
    
}



//22 dec 2021
.rdw-editor-wrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    div,
    span,
    * {
      font-size: inherit;
    }
  }
}
.settings_container {
  .rdw-editor-wrapper {
    h1 {
      font-size: 36px;
      div,
      span,
      * {
        font-size: inherit;
      }
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 14px;
    }
    h6 {
      font-size: 12px;
    }
  }
}
