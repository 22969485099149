/********* Resource Page */

.m-r-1{
  margin-right: 1rem;
}
.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px;
}
.no_crews {
  min-height: 168px;
  float: left;
  width: 100%;
  line-height: 168px;
}
.sortableHelper {
  z-index: 99999 !important;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eef0f2;
  border-bottom: 0;
  cursor: pointer;
  float: left;
  width: 100%;
}
.fs-pagination-wrapper {
  margin-top: 10px;
  float: right;
  margin-right: 10px;
}
.fs-pagination {
  padding-left: 0px;
  display: inline-block;
}
.fs-pagination li {
  display: inline-block;
  padding: 5px 10px;
  position: relative;
}
.fs-pagination li.active:before {
  content: "";
  position: absolute;
  bottom: 0px;
  background: #488941;
  height: 2px;
  width: 8px;
}
.error-message{
  color: red;
}
.print-icon{
  margin-right: 5px;
}
.employees-print-button{
  float: right;
  margin: 5px;
  margin-bottom: 10px;
}
.employees-print-button-loader{
  float: right;
  width:50px;
}
.popup-text-label{
  font-size: 14px!important;
}
.fs-pagination-wrapper-outer {
  width: 100%;
  float: left;
  border-bottom: 1px solid #eef0f2 !important;
  border-left: 1px solid #eef0f2 !important;
  border-right: 1px solid #eef0f2 !important;
}
.fs-pagination li a {
  color: #000;
  cursor: pointer;
}
.fs-pagination li.active a {
  color: #00703C;
  /* font-weight: bold; */
}
.fs-pagination li.active {
  font-weight: bold;
  font-size: 16px;
}
.fs-pagination-wrapper span {
  display: inline-block;
  width: 25px;
  cursor: pointer;
  height: 25px;
}
.fs-pagination-wrapper span img {
  width: 100%;
}
.sortableHelper .list_item {
  flex: 1 0 20%;
  border: 0;
  line-height: 45px;
  text-transform: capitalize;
}
.sortableHelper .image-holder {
  flex: 1 0 7% !important;
}
.btn_list {
  margin-top: 20px;
  float: right;
}
.resource_container .page_title {
  padding-bottom: 0;
}
.resource_container .page_title h1 {
  display: inline-block;
  float: left;
  font-size: 24px;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.btn_list .btn {
  background: #00703C;
  color: #fff;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  padding: 4px 10px;
}
.resource_details p.noEmployees {
  text-align: center;
  margin: 10px 0;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
/*button.btn:hover {
    background: #C9C288;
}*/
.resource_item {
  float: left;
  text-align: left;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  padding: 10px;
}
.resource_list {
  background: #fff;
  width: 100%;
  float: left;
  border-radius: 3px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  box-shadow: 6px 7px 10px -4px #dfe0e2;
}
.resource_item p {
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  font-size: 12px;
  margin-top: 10px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.resource_container .btn_list .button {
  margin-left: 10px;
}
.resource_container .btn_list a.button:hover,
a.cancel_res.button:hover {
  text-decoration: none;
  color: #fff;
}
.resource_item img {
  width: 120px;
  height: 120px;
  border-radius: 3px;
  object-fit: cover;
}
.form.resourceForm {
  width: 50%;
  margin: 0 auto;
  padding: 25px 0px;
}
.resourceForm .formField {
  padding: 0px;
}
.resourceForm .file_div {
  margin-bottom: 30px;
}
a.cancel_res.button {
  float: left;
}
.resourceForm .upload_or_drag img.profileImg {
  top: 5px;
}
.file_div.formField label {
  float: none;
}
.resource_list p.noResult {
  text-align: center;
  margin-top: 25px;
}
.create_resource_page .resourceForm {
  width: 50%;
  margin: 40px auto 0;
}
/*** Vehicle - STARTS **/
.add_vehicle_btn {
  margin-left: 10px;
  cursor: pointer;
}
.vehicleList .list_header,
.vehicleList .list_item {
  display: flex;
  flex-wrap: wrap;
}
.vehicleList .list_item_head,
.vehicleList .list_item .item_cell {
  flex: 1 0;
}
.vehicleList .actions.item_cell span {
  margin: 0 10px;
  color: #3f7e39;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  cursor: pointer;
}
.vehicleList .actions.item_cell {
  margin-top: 0;
}
.addVehicleForm ul,
.editVehicleForm ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  margin-top: -22px;
}
/*input#resource_search {
    margin-bottom:  0;
}*/
.addVehicleForm li.item,
.editVehicleForm li.item {
  padding: 15px 10px;
  border: 1px solid #d0d3d4;
  border-bottom: 0;
  cursor: pointer;
}
.addVehicleForm li.item:last-child,
.editVehicleForm li.item:last-child {
  border: 1px solid #d0d3d4;
  border-radius: 0px 0px 3px 3px;
}
.addVehicleForm li.item:first-child,
.editVehicleForm li.item:first-child {
  border-top: 0;
}
.addVehicleForm label,
.editVehicleForm label {
  width: 100%;
}
.addVehicleForm .react-datepicker__input-container,
.editVehicleForm .react-datepicker-wrapper,
.editVehicleForm .react-datepicker__input-container,
.addVehicleForm .react-datepicker-wrapper {
  width: 100%;
}
.vehicleList .goback {
  margin-bottom: 15px;
}
.inner_container.vehicleList {
  width: 100%;
}
/** Vehicle - ENDS ***/

/*** Resource listing - STARTS **/
[data-react-beautiful-dnd-drag-handle="0"] {
  float: left;
}
.firefighter_wrapper img {
  width: 150px;
  height: 150px;
  border-radius: 3px;
}
.resource_list .resource_search,
.resource_details .resource_search,
.your_crew_div .resource_search {
  float: right;
  width: 300px;
  padding: 10px;
  margin-bottom: 0;
}
.resource_filter{
  display: flex;
  align-items: flex-start;
}

/* Resource Employee Listing Classes */
.docs-status .mobile-status{
  display:none;
}

@media screen and (max-width: 480px){
  .resource_container .resource_filter{
    flex-direction: column;
  }

  .resource_container .resource_filter .filter_item{
    margin-left: auto;
    margin-right: auto;
  }
}
.resource_filter .filter_item{
  padding: 10px;
  margin:5px 15px;
}
.resource_filter button.filter_item{
  padding: 8px 14px;
  font-size: 16px;
 
}
.resource_container .btn_list .button.filter_item{
  border: none;
  padding: 5px 12px;
  opacity: 0.65;
}

@media screen and (max-width: 969px){
  .resource_container .btn_list .button.filter_item{
    width: 100%;
  }
 
}

.resource_container .resource_list{
  box-shadow: none;
  padding-bottom: 0px;
}
.your_crew_div .list_item_head.phone,
.your_crew_div .list_item.phone {
  flex: 1 0 10% !important;
}
.your_crew_div .list_item_head.email,
.your_crew_div .list_item.email {
  flex: 1 0 30% !important;
}
.resource_list .inner_head h2 {
  float: left;
}
.no_resource,
.no_employee {
  text-align: center;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  padding: 25px 0 0;
}
.no_employee {
  padding: 10px 0 0;
}
/*** Resource listing - ENDS **/

.test li {
  width: 250px;
  height: 200px;
  float: left;
  background: lightblue;
  margin: 8px 7px;
}

.test .container {
  background-color: tomato;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.box {
  background-color: steelblue;
  color: #ffffff;
  font-size: 24px;
  line-height: 100px;
  margin: 0.5%;
  width: 49%;
  height: 100px;
  text-align: center;
}

.box:hover {
  cursor: move;
}

/* slider component*/
.slider-container {
  margin: 0 auto;
  margin-top: 25px;
  width: 15%;
}

.slider {
  width: 100%;
  margin: 0;
}

.slider[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
span.status {
  width: 15px;
  height: 15px;
  display: inline-block;
  /* position: absolute; */
  margin-right: 5px;
}

@media only screen and (max-width: 549px){
.resource_item p {
    height: 21px !important;
}
}
.status img {
  width: 100%;
  height: auto;
}
@media screen and (max-width:700px){
  .status img {
    width: 100% !important;
    height: auto !important;
    min-height: 15px !important ;
    max-height: 15px !important ;
  }
}


.slider-trashhold-container {
  display: flex;
  justify-content: space-between;
  color: #ccc;
  font-size: 10px;
}


/** Crew - STARTS **/
/*.crew_wrapper {
    padding-top: 10px !important;
}*/
.crew_outer .inner_head {
  min-height: 56px;
}
.crew_wrapper h5 {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}

.crew_outer .outer_list {
  margin: 10px 0;
  float: left;
  width: 100%;
}

.crew_outer .outer_list {
  margin:0 !important;
}

.crew_wrapper .employee_item .image-holder img {
  width: 46px;
  border-radius: 100%;
  height: 46px;
  border: 3px solid #dc0223;
  object-fit: cover;
}
.crew_wrapper .employee_item .image-holder img.Available {
  border: 3px solid #51dd76;
}
.crew_wrapper .employee_list{
  position: relative;
}
.crew_wrapper .employee_item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eef0f2;
  border-bottom: 0;
  cursor: pointer;
}
.crew_wrapper .employee_item .list_item,
.crew_wrapper .employee_list .list_item_head {
  flex: 1 0 20%;
  border: 0;
}
.employee_list .image-holder {
  flex: 1 0 7% !important;
}
/* Bulk Edit changes */
.crew_wrapper .employee_item.bulk-select .list_item,
.crew_wrapper .employee_list.bulk-select .list_item_head {
  flex: 1 0 100px;
  border: 0;
}
.crew_wrapper .employee_item.bulk-select .list_item.custom-checkbox,
.crew_wrapper .employee_list.bulk-select .list_item_head.custom-checkbox {
  max-width: 50px
}



.crew_wrapper .employee_list.bulk-select .button-list button{
  margin-right: 15px;
}

.employee_list.bulk-select .image-holder {
  flex: 1 0 80px !important;
}
/* End of Bulk Edit changes */


.crew_wrapper .employee_item:last-child {
  border-bottom: 1px solid #eef0f2;
}
.color_code {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  min-width: 10px;
  display: inline-block;
}
.Yellow.color_code {
  background: #e6e432;
}
.Red.color_code {
  background: #dc0223;
}
.Blue.color_code {
  background: #3440d9;
}
.Grey.color_code {
  background: #a8a8a8;
}
.White.color_code {
  background: #fff;
  border: 1px solid #202020;
}
.crew_wrapper .employee_list .list_item_head {
  padding: 10px;
}
.crew_wrapper .employee_list .list_header {
  padding: 0;
}
.crew_wrapper .employee_list .emp_name {
  text-transform: capitalize;
}
.crew_wrapper .employee_list .list_item {
  line-height: 45px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

/** Crew - ENDS **/

#emailPopup .button,
#removePopup .button {
  margin: 0 5px;
}
#removePopup label {
  padding: 30px 10px;
}
#removePopup .button {
  margin: 0 5px 15px;
}

/*#emailPopup .btn_list {
    margin-top: 5px;
    margin-bottom: 10px;
}*/

/*** Manifest - STARTS ***/

.manifest_type_form {
  width: 320px;
  padding: 40px 0 0;
  margin: 0 auto;
  margin-bottom: 40px;
}

.manifest_type_form .btn_list {
  float: none;
  text-align: center;
}

.manifest_type_form .btn_list {
  float: none;
  text-align: center;
  margin-top: 5px;
}

.manifest_type_form label {
  text-align: center;
  margin-bottom: 20px;
}
h3.drag_manifest_title {
  font-size: 15px;
  width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 35px;
  float: left;
  color: #3f7e39;
  margin: 0;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.drag_manifest_box {
  width: 50%;
  padding: 0 10px 35px;
  float: left;
  background: #fff;
  margin-bottom: 35px;
  min-height: 100px;
  height: 100%;
  position: relative;
  /* overflow: hidden; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.manifest_sort {
  padding: 0;
  min-height: 100%;
  height: 100%;
  margin: 0;
  overflow-y: auto;
  min-height: 400px;
}
.manifest_sort li {
  list-style-type: none;
  background: transparent;
  border: 0;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  cursor: move;
  margin: 0px 0 -1px;
  padding: 3px 0;
  float: left;
  width: 100%;
}
.drag_manifest_img {
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  text-align: left;
}
.drag_manifest_img img {
  max-width: 100%;
  display: inline-block;
  max-height: 78px;
  min-height: 20px;
  width: 20px;
  margin: 1px 0;
  float: left;
  margin-right: 10px;
}
#drag_maifest_left {
  border-right: 2px solid #000;
}
.drag_manifest_img .pos_hold {
  float: left;
  line-height: 22px;
  padding: 0 10px 0 0;
  font-weight: 600;
  width: 22px;
  text-align: right;
}
.drag_name {
  text-transform: capitalize;
}

/*.temp{
    display: flex;
    float: left;
    justify-content: space-between;
}*/

/* draggable targets */
/*[data-draggable="target"]
{
    float:left;
    list-style-type:none;

    width:42%;
    height:7.5em;
    overflow-y:auto;

    margin:0 0.5em 0.5em 0;
    padding:0.5em;

    border:2px solid #888;
    border-radius:0.2em;

    background:#ddd;
    color:#555;
}*/

/* draggable items */
/*[data-draggable="item"]
{
    display:block;
    list-style-type:none;

    margin:0 0 2px 0;
    padding:0.2em 0.4em;

    border-radius:0.2em;

    line-height:1.3;
}
*/
/*** Manifest - ENDS ***/

.archived_list {
  margin-top: 20px;
}
.appln_name {
  text-transform: capitalize;
}
.archived_wrapper .list_item {
  border: 0;
  padding: 0;
}
.archived_wrapper .list_item_head,
.archived_wrapper .list_item {
  flex: 1 0 15% !important;
}
.archived_wrapper .appln_phone,
.archived_wrapper .phone {
  flex: 1 0 5% !important;
}
.archived_wrapper .unarchive {
  flex: 1 0 1% !important;
  cursor: pointer;
  color: #00703C;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.archived_wrapper .appln_name.list_item p {
  font-size: 13px;
}
.content_div.archived_wrapper {
  padding-top: 20px;
}
.unarchivePopup .btn_list {
  float: none;
}
.res_delete.step1 {
  font-size: 14px;
  padding: 25px 0;
}

.action_icon {
  background: url(../images/dots.png) no-repeat top 20px center;
  width: 25px;
  background-size: 18px;
}
.manifest_list .list_item_head,
.manifest_list .list_item {
  flex: 1 0 16.66% !important;
}
.manifest_list .list-view{
  display:block;
}
.manifest_list .list-view:hover{
  background-color: #f9f9f9;
}
.manifest_list .list_item_head.action_icon,
.manifest_list .list_item.action_icon {
  flex: 1 0 2%;
}
.manifest_list .employee_item {
  cursor: default;
  border-bottom: 0 !important;
}
.manifest_list .employee_item:last-child {
  border-bottom: 1px solid #eef0f2 !important;
}
.manifest_list .employee_item:hover {
  border: 1px solid #3f7e39 !important;
  background: #f7f9fa;
  /*cursor: pointer;*/
}
.manifest_list .list_item {
  padding: 13px 10px;
}
.manifest_list .list_item.action_icon {
  cursor: pointer;
}
.resource_list_container{
  width: 100% !important;

}
.resource_list .list_item_head{
  white-space: break-spaces;
  text-align: left;

}
.resource_list .list_item{
  border-left: 0px;
  border-right: 0px;
  width: 16.66%;
  white-space: break-spaces;
  text-align: left;
}
.resource_list-head{
  background-color: #edf0f2;
}
.resource_list .list_item:first-child{
  border-left: 1px solid #eef0f2;
}
.resource_list .list_item:last-child{
  border-right: 1px solid #eef0f2;
}
.resource_list_table {
  table-layout: fixed;
  border: 1px solid #ddd;
  margin-bottom: 0px;
}
.resource_list_table td{ 
  text-align: left;
  padding: 10px !important;
  white-space: break-spaces;
  word-wrap: break-word;
  min-height: 40px;
}
.resource_list_table td a{
  color: #202020;
}

.action_list {
  width: 100%;
  line-height: 40px;
  border-bottom: 0 !important;
  height: 45px;
  float: left;
  border: 1px solid #eef0f2;
}
.action_list p {
  float: left;
  width: 20%;
  text-align: center;
  color: #3f7e39;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  cursor: pointer;
}
.btn_list_popup .button {
  margin: 25px 5px !important;
  width: 85px;
}
.manifest_items:last-child .action_list {
  border-bottom: 1px solid #eef0f2 !important;
}
.mail_div {
  padding-top: 15px;
}
.mail_div .left,
.mail_div .right {
  width: 100%;
  text-align: left;
}
.mail_div #txtSearchManiEmployees {
  width: 280px;
  float: left;
  margin-bottom: 0;
}
.mail_div .send_mail {
  float: right;
}
.mail_div .sig_message {
  text-align: left;
  float: left;
  width: 100%;
  margin-top: 20px;
}
.emailManifestPopup .ReactModal__Content.ReactModal__Content--after-open {
  bottom: unset !important;
  height: unset !important;
}
.mail_div ul.searchResultUl {
  float: left;
  width: 280px;
  padding: 10px 15px 5px;
}
.mail_div ul.searchResultUl li {
  float: left;
  width: 100%;
  text-align: left;
  padding: 5px 0;
  cursor: pointer;
}
.employee_item .image-holder img {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  border: 3px solid;
}
.employee_item .image-holder img.Unavailable {
  border-color: #dc0223;
}
.employee_item .image-holder img.Available {
  border-color: #51dd76;
}

.in_manifest .employee_item {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eef0f2;
  border-bottom: 0;
  cursor: pointer;
}
.in_manifest .employee_item .list_item {
  flex: 1 0 20%;
  border: 0;
  line-height: 45px;
}
.employee_list.outer_list {
  float: left;
  width: 100%;
  margin-top: 15px;
}
.sendMail_btn {
  color: #3f7e39;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}

.viewManifestPopup .ReactModal__Content.ReactModal__Content--after-open {
  height: 100%;
}

.react-pdf__Page__textContent {
  display: none;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  position: relative;
  top: -50px;
}
.react-pdf__Page {
  top: 50px;
}

/** Sort your crew - STARTS **/

.draggable {
  border-top: 1px solid #ccc;
  text-align: left;
  padding: 2px 0;
}

.draggable img {
  width: 20px;
  margin: 0 5px;
  border-radius: 50%;
}
.draggable img.active{
  border: 2px solid rgb(72, 180, 72);
}
.draggable img.inactive{
  border: 2px solid rgb(214, 71, 71);
}
.sort_crew_div {
  width: 600px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}
.sort_crew_div .drag_sort_wrap {
  width: 100%;
  padding: 65px 0 25px;
}
.selected.drag_manifest_box {
  border-right: 2px solid #d8d8d8;
  padding-bottom: 35px;
}
.draggable span {
  text-transform: capitalize;
}
.sort_crew_div .btn_list {
  float: none;
}
.drag_manifest_box .task-header {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.draggable:last-child {
  border-bottom: 1px solid #ccc;
}
.sort_crew_div .draggable span.pos_hold {
  width: 15px;
  float: left;
}
/** Sort your crew - ENDS **/

.cust_email_options {
  text-align: center;
  margin-bottom: 20px;
}
.cust_email_options .option_email,
.cust_email_options .option_sms {
  display: inline-block;
  margin: 0 10px;
}
.cust_email_options .option_email input[type="checkbox"],
.cust_email_options .option_sms input[type="checkbox"] {
  float: left;
  width: auto;
  margin-right: 10px;
}
.cust_email_options span {
  float: left;
}
.status_step .custom_email_bt_wrp button.button {
  margin: 0px 10px !important;
}
/** create profile **/

.create_resource_page .goback {
  margin: 20px;
}
.edit_resource_page .goback {
  margin: 25px 25px 0;
}
.Showcase_Content_wrapper {
  display: block;
  white-space: nowrap;
  border: 0px;
  background-color: transparent;
  width: calc(100% - 5px);
  height: 1650 px;
}
.Showcase_Content_wrapper > div {
  height: 100%;
}
.dragging {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.dragging .resource_item {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.listResourcePage .resource_list {
  min-height: 250px;
  position: relative;
}
.your_crew_div .applicants_inner_head.crew_outer {
  position: relative;
  min-height: 500px;
  width: 100%;
  padding: 0;
}
.DeleteVehPopup .ReactModal__Content.ReactModal__Content--after-open {
  width: 35%;
  height: 175px;
}
#deleteVehPopup .modal-body {
  padding-top: 20px;
}
.crew_inventory .inventory_item {
  float: left;
  margin: 15px 0;
  width: 25%;
}

.deletetPopup .ReactModal__Content.ReactModal__Content--after-open {
  width: 35%;
  height: 212px;
}

@Media screen and (max-width:767px){
  .deletetPopup-resources .ReactModal__Content.ReactModal__Content--after-open{
    width: 90%;
  }
  .docs-status .note_item{
    margin-left: 0;
    display:flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .docs-status .mobile-status{
    padding: 10px;
    display:inline-block;
  }
}
.deletetPopup .btn_list {
  float: none;
}

.ReactModal__Content.ReactModal__Content--after-open img.closeModal {
  margin: -2px 0px;
}
.ReactModal__Content.ReactModal__Content--after-open .modal-head {
  min-height: 45px;
}
.ReactModal__Content.ReactModal__Content--after-open h4.modal-title {
  display: inline-block;
  text-align: left;
  float: left;
}
.resource_list .resource_item .res_img {
  background-size: 150px 150px !important;
  width: 150px;
  height: 150px;
  background-position: 50% 50% !important;
  float: left;
  margin-bottom: 10px;
  border-radius: 5px;
}
.edit_resource_page .upload_or_drag p,
.create_resource_page .upload_or_drag p {
  /*margin-top: 30px;*/
  margin-bottom: 0px;
  height: 80px;
  padding: 40px 0;
}

.emp_verify_wrap p.emp_status {
  background: #00703C;
  /* margin: 20px; */
  padding: 6px 20px;
  border-radius:3px;
  /* width: 250px; */
  text-align: center;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  color: #ffffff;
}
.link_resource {
  display: inline-block;
  float: left;
}
.emp_link_resource,
.emp_link_resource .employee_item {
  width: 100%;
  display: flex;
}
.create_resource_page .upload_or_drag,
.edit_resource_page .upload_or_drag {
  margin-bottom: 25px;
}
.editProfile_outer .breadcrumbs {
  margin: 0px -15px 25px;
}
.edit_profile_pic img.imagePreview {
  height: 100%;
  object-fit: cover;
}
.empStatusPopup .ReactModal__Content.ReactModal__Content--after-open {
  width: 35%;
  /* height: 325px; */
}


.user-status-edit-wrapper{
  display: flex;
  justify-content: center;
}

.user-status-edit-wrapper div{
  padding: 10px;
}

.resource_details .employee_list .list_item_head.phone,
.resource_details .employee_list .list_item.phone,
.resource_details .employee_list .list_item_head.usertype,
.resource_details .employee_list .list_item.usertype,
.your_crew_div .employee_list .list_item_head.phone,
.your_crew_div .employee_list .list_item.phone,
.your_crew_div .employee_list .list_item_head.usertype,
.your_crew_div .employee_list .list_item.usertype {
  flex: 1 0 15%;
}

.resource_details .employee_list .list_item.email,
.resource_details .employee_list .list_item_head.email,
.your_crew_div .employee_list .list_item.email,
.your_crew_div .employee_list .list_item_head.email {
  flex: 1 0 30%;
}

.resource_details span.pos_counter {
  margin: 0 5px 0 0;
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  width: 20px;
}
.listResourcePage .inner_head {
  margin-bottom: 20px;
}

@media screen and (max-width: 1060px) {
  .resource_details .list_item.item_width.email {
    white-space: nowrap;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }

  .resource_details .list_item_head.email {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }
}

@media screen and (min-width: 1060px) {
  .resource_details .list_item.item_width.email {
    min-width: 300px;
  }
}

.resource_details .list_item.image-holder.item_width {
  max-width: 90px;
}

[data-react-beautiful-dnd-draggable] {
  width: 100%;
}

.draggable {
  position: relative;
  background: #fff;
}
.draggable:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.dragConList {
  display: flex;
  flex-direction: column;
}
.row-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row-flex .heading-section {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  font-size: 13px;
  color: #202020;
  flex: auto;
  width: 250px;
  max-width: 250px;
}
.__drag-section {
  display: flex;
  justify-content: space-around;
  margin-top: 9px;
}

.vehicle_list a.vehicleLink {
  display: flex;
  width: 100%;
}
.emailManifestPopup .mail_div .left {
  position: relative;
}
.emailManifestPopup .mail_div ul.searchResultUl {
  top: 40px;
  width: 90%;
  max-width:400px;
  height: 120px;
}
.empStatusPopup.resEmpStatusPop
  .ReactModal__Content.ReactModal__Content--after-open {
  overflow: initial !important;
}
.empStatusPopup.resEmpStatusPop
  .react-datepicker-popper[data-placement^="bottom"] {
  top: 0 !important;
}
.ncc_sign #sup_det_create,
.ncc_sign #sup_phone_create,
.ncc_sign .print_date {
  margin-top: 3px;
}
.ifca_sign .table.last_table .tr .td:first-child {
  line-height: initial;
}
.employeeOuterDiv {
  display: flex;
  flex-direction: column;
}
.employeeOuterDiv .employee_item {
  border: 0 !important;
}
.list_item.close_mail.second_width img,
.employee_list .close_mail img {
  width: 21px;
}
.list_item.close_mail {
  flex: 1 0 5% !important;
}
.label_bar.current_qualification {
  margin-bottom: 15px;
}

.resource_item {
  background-color: #fff;
}

.mail-maifest-wrapper{
  max-height: 241px;
  overflow-y: scroll;
  margin: 20px 0px;
}

.mail-manifest.wrapper{
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-size: 13px;
}

.mail-manifest.wrapper .head{
  flex: 0 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #eef0f2;
  border: 1px solid #eef0f2;
}

.mail-manifest.wrapper .head div{
  flex: 0 25%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.mail-manifest.wrapper .head div.emp{
  text-align: center;
  flex: 0 10%;
}

.mail-manifest.wrapper .head div.name{
  flex: 0 25%;
}

.mail-manifest.wrapper .head div.email{

  flex: 0 55%;
}

.mail-manifest.wrapper .head div.send-mail{

  flex: 0 15%;
}

.mail-manifest.wrapper .head div.close{
  text-align: center;
  flex: 0 9%;
}

.mail-manifest.wrapper .employee{
  flex: 0 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eef0f2;
  border-top: none;
}

.mail-manifest.wrapper .employee div{
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-all;
}

.mail-manifest.wrapper .employee div.pic{
  flex: 0 10%;
  align-items: center;
}

.mail-manifest.wrapper .employee div.name{
  flex: 0 25%;
}

.mail-manifest.wrapper .employee div.email{
  flex: 0 55%;
}

.mail-manifest.wrapper .employee div.send-button{
  flex: 0 15%;
}

.mail-manifest.wrapper .employee div.close-btn{
  align-items: center;
  flex: 0 10%;
}

.mail-manifest.wrapper .employee div.send-button{
  cursor: pointer;
  text-align: center;
  color: #3f7e39;
  font-weight: 900;
}

.mail-manifest.wrapper .employee div.close-btn img{
  width: 21px;
  cursor: pointer;
  opacity: .7;
}
.mail-manifest.wrapper .employee div.close-btn img:hover{
  opacity: 1;
}

.resource_archived_list.archived_wrapper .list_item_head,
.resource_archived_list.archived_wrapper .list_item {
  -ms-flex: 0 0 30% !important;
  flex: 0 0 30% !important;
  display: flex;
  align-items: center;
}

.resource_archived_list.archived_wrapper .list_item_head:first-child,
.resource_archived_list.archived_wrapper .list_item:first-child {
  -ms-flex: 0 0 10% !important;
  flex: 0 0 10% !important;
  display: flex;
  align-items: center;
}

.resource_archived_list.archived_wrapper .list_item .pic{
  width: 46px;
  margin-left: 10px;
  border: 3px solid #51dd76;
  border-radius: 100%;
}

.name-list-wrapper
{
  position: relative;
}

ul.name-list{
  padding: 0px;
  position: absolute;
  top:75px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d0d3d4;
  border-radius: 5px;
  outline: 0;
  transform: translateX(-10px);
}
ul.name-list li{
  padding: 8px 10px;
  border-bottom: 1px solid #d0d3d4;
  cursor: pointer;
}

ul.name-list li:first-child{
  background: #d0d3d4;
  height: 25px;
  cursor:auto;
}

ul.name-list li:first-child img{
  float:right;
  width: 10px;
  cursor: pointer;
}

ul.name-list li:last-child{
  border-bottom: none;
}

.mail_div .add_mail
{
  margin-top:5px;
  margin-left:10px;
}

.emailManifestPopup .mail_div ul.searchResultUl,
.mail_div #txtSearchManiEmployees {
  max-width: 400px;
}

@media screen and (max-width:1080px){

  .mail-maifest-wrapper{
    max-height: 443px;
  }

  .mail-manifest.wrapper .head div.emp {
    flex: 0 1 15%;
  }

  .mail-manifest.wrapper .head div.close {
    flex: 0 1 5%;
  }

  .mail-manifest.wrapper .employee div.pic {
    flex: 0 1 15%;
  }

  .mail-manifest.wrapper .employee div.email {
    flex: 0 1 50%;
  }

  .resource_archived_list.archived_wrapper .list_item_head:first-child,
  .resource_archived_list.archived_wrapper .list_item:first-child {
    -ms-flex: 0 0 20% !important;
    flex: 0 0 20% !important;
    display: flex;
    align-items: center;
  }

  .resource_archived_list.archived_wrapper .list_item_head,
  .resource_archived_list.archived_wrapper .list_item {
    -ms-flex: 0 0 40% !important;
    flex: 0 0 40% !important;
    display: flex;
    align-items: center;
  }

  .resource_archived_list.archived_wrapper .list_item_head.unarchive,
  .resource_archived_list.archived_wrapper .list_item.unarchive {
    -ms-flex: 0 0 10% !important;
    flex: 0 0 10% !important;

  }

  .resource_archived_list.archived_wrapper .list_item_head.type,
  .resource_archived_list.archived_wrapper .list_item.type {
    -ms-flex: 0 0 22% !important;
    flex: 0 0 22% !important;

  }

}

@media screen and (max-width:480px){

  .resource_archived_list.archived_wrapper .list_item_head,
  .resource_archived_list.archived_wrapper .list_item {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    display: flex;
    align-items: center;
  }

  .resource_archived_list.archived_wrapper .list_item_head:first-child,
  .resource_archived_list.archived_wrapper .list_item:first-child {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    display: flex;
    align-items: center;
  }

  .resource_archived_list.archived_wrapper .list_item_head.unarchive,
  .resource_archived_list.archived_wrapper .list_item.unarchive {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;

  }

  .resource_archived_list.archived_wrapper .list_item_head.type,
  .resource_archived_list.archived_wrapper .list_item.type {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;

  }

}
@media screen and (max-width:550px){

  .form-field-bottom-controls button,.form-field-bottom-controls input[type="submit"]{
    width: 100% ;
    margin-bottom: 15px;
  }
  .form-field-bottom-controls .right{
    float: left ;
    width: 100% ;
  }

  .mail-maifest-wrapper {
    max-height: 180px;
  }

  .mail-manifest.wrapper .head{
    display: none;
  }

  .mail-manifest.wrapper .employee{
    border-top: 1px solid #eef0f2;
    padding: 15px 10px;
    margin-bottom: 15px;
  }

  .mail-manifest.wrapper .employee div{
    padding: 5px;
  }

  .mail-manifest.wrapper .employee div.pic{
    flex: 0 100%;
  }

  .mail-manifest.wrapper .employee div.name{
    flex: 0 100%;
    text-align: center;
  }

  .mail-manifest.wrapper .employee div.email{
    flex: 0 100%;
    text-align: center;
  }

  .mail-manifest.wrapper .employee div.send-button{
    flex: 0 100%;
    text-align: center;
  }

  .mail-manifest.wrapper .employee div.close-btn{
    flex: 0 100%;
    text-align: center;
  }

}



.editResource_delete_icon_container{
  position: relative;
}

.editResource_delete_icon{
  background: url("../../assets/images/close_button.png") no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px;
  position: absolute;
  right: -2%;
  top: -2%;
  cursor: pointer;
  background-color: #fff;
  border-radius: 15px;
  /* border: 1px solid gray; */
}