.status-wrapper{
  border: 1px solid #eef0f2;
}
.status-wrapper .available{
  width: 170px;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  font-weight: 600;
  color: #00703C;
  border: 1px solid rgba(63,126,57,0.55);
  background: rgba(63,126,57,0.05);
  border-radius: 20px;
  position: relative;
}
.status-wrapper .unavailable{
  width: 170px;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  font-weight: 600;
  color:rgb(217, 52, 58);
  border: 1px solid rgb(217, 52, 58);
  background: rgba(217, 52, 0, 0.05);
  border-radius: 20px;
  position: relative;
}
@media screen and (max-width: 992px){
  .status-wrapper .unavailable{
      padding-left: 30px;
  }
}
.status-wrapper .committed {
  width: 170px;
  text-align: center;
  display: inline-block;
  padding: 10px 15px 10px 33px;
  font-weight: 600;
  color: rgb(150, 150, 150);
  border: 1px solid rgb(150, 150, 150);
  background: rgba(150, 150, 150, 0.05);
  border-radius: 20px;
  position: relative;
}
.status-wrapper .current-status{
  width: 210px;
    display: block;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
.status-wrapper .row{
  display: flex;
  flex-wrap: wrap;
}
.editor{
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
.editor img{
  width: 100%;
}
.status-image{
  width: 30px;
  height: 30px;
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.status-image img{
  width: 100%;
}
.summary-box{
  padding: 20px 50px;
  border-left: 1px solid #eef0f2;
  height: 100%;
}
.summary-box p,h4{
  margin-bottom: 15px;
}
.lists-outer-row{
  margin-bottom: 15px;
}
.lists-outer-row .shifts{
  display: inline-block;
  padding-right: 50px;
}
.list_item_head_trash {
  max-width: 30px;
  display: inline-block;
  min-width: 30px!important;
}
.list_item_trash {
  max-width: 30px;
  display: inline-block;
  min-width: 30px!important;
}
.list_item_delete_mobile{
  text-align: center;
  font-weight: 700;
}
.list_item_delete_mobile img{
  margin-top: -2px;
}
.list_item_delete_mobile a{
  color: #000;
}
.status_item_grey{
  background: #f7f7f7;
}
.list_item_trash img{
  display:block;
  margin: auto;
}
.summary-box h5{
  font-size: 15px;
}
.summary-box .shifts strong{
  font-weight: 600;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.status_item{
  display: flex;
  width: 100%;
  border: 1px solid #eef0f2;
  padding: 0px 10px
}
.history_lists .list_header .list_item_head{
  width: 10%;
}
.status_item .list_item {
  flex: 1 0;
  border: none;
  padding-left: 0px;
  min-width: 10%;
}
.status_item .list_item a{
  color: #000;
}

.status-popup-outer{
  padding: 15px;
}
.change-status-pop{
  padding: 0px 30px 30px;
  min-height: 500px;
  overflow: auto;
  overflow-x: hidden;
}
.change-stat-head{
  padding: 12px 30px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 30px;
}
.change-status-pop .form-control{
  height: 40px;
  border: 1px solid #cfd3d4;
  border-radius: 3px;
}

.ios .change-status-pop textarea.form-control{
  padding-left: 6px;
  padding-right: 6px;
}
.change-status-pop input{
  width: 100%;
  height: 40px;
  font-size: 13px;
}
.change-status-pop textarea{
  width: 100%;
  height:100px!important;
}
.change-status-pop .react-datepicker-wrapper{
  width: 100%;
}
.change-status-pop .react-datepicker__input-container{
  width: 100%;
}
.change-status-pop .btn{
  padding: 8px 20px;
  border-radius: 0px;
  background: #00703C;
  border:none;
  min-width: 80px;
}
.change-status-pop .cancel{
  float: right;
  background: #f0f0f0;
  color: #202020;
  border:none;
}
.change-status-pop .form-group label{
  font-weight: 400;
  color: #202020;
  font-family:"Proxima Nova", Georgia, sans-serif;
}
.date-outer{
  position: relative;
  display: inline-block;
  width: 100%;
}
.date-outer .datepicker-image{
  right: 10px;
  top: 10px;
}
.for_mobile_history_list{
  display: none;
}
.notes_ellipsis{
  width:90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.note_full{
  position: absolute;
  left: 0;
  bottom: 100%;
  max-width: 250px;
  background: #202020;
  padding: 8px;
  color: #fff;
  display: none ;
  z-index: 500;
}
.note_full.docs-status{
  min-width:250px;
  color:#fff !important;
  left: unset;
  right: 0;
}
.note_full:after{
  border: solid 5px #171717;
  content: "";
  position: absolute;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -10px;
  left: 5px;
}
.note_full.docs-status:after{
  left: unset;
  right: 5px;
}
.note_item{
  position: relative;
}
.note_item:focus .note_full,
.note_item:active .note_full,
.note_item:hover .note_full{
  display: block ;
}
.notes_ellipsis:after{
  position: absolute ;
  /* content: attr(title); */
  content: "";
}
.center_items{
  text-align: center;
}
@media screen and (max-width:1300px){
  .status-wrapper .current-status{
    width: 100%;
    margin-left: 15px;
  }
  .status-wrapper .available{
    width: 80%;
  }
  .status-wrapper .unavailable{
    width: 80%;
  }
  .status-wrapper .committed{
    width: 80%;
  }
}
@media screen and (max-width:991px){
  .status-image {
    width: 20px;
    height: 20px;
  } 
  .editor{
    margin-left: 5px;
  }
}
@media screen and (max-width:768px){
  .status-wrapper .current-status{
    width: 240px;
    padding-left:30px;
  }
  .for_mobile_history_list{
    display: block;
    word-break: break-word;

  }
  .mobile_hide{
    display: none;
  }
  .fs-pagination-wrapper-outer {
     border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0!important;
  }
}
@media screen and (max-width:600px){
  .vehicle_list a.vehicleLink {
    flex-direction: column;
    
}
}
@media only screen and (max-width: 600px){
  .vehicle_list.outer_list .basic_info div {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    text-align: center;
    width: 100%;
    text-align: initial;
    flex: initial !important;
}
}


.inv-sidemenu-box {
  border-left: solid 1px #fdfdfd;
  box-shadow: -1px 0px 15px -1px rgba(0,0,0,.1);
}

.resource_list .resource_search{
  margin-left: 11px;
  max-width: 44% ;
}

@media screen and (max-width:993px){
  .resource_list .resource_search{
    float: left;
    margin-left: 0 ;
    margin-right: 11px;
  }
}

@media screen and (max-width:767px){
  .resource_list .resource_search{
    float: left;
    width: 100% !important ;
    max-width: 100% ;
    margin-bottom: 11px;
    margin-left: 0 ;
    margin-right: 0 ;
  }
}


@media screen and (max-width:993px) and (min-width:600px){
  .filter--wrapper{
    padding-left: 30px;
  }
}

.print_button{
  color: #fff !important ;
}

.sort-arrow.not-active{
  opacity: .2 !important;
}


.page-employees-selected{
  background-color: #EEF7ED !important;
}

.page-employees-selected .td-center{
  color: #555555;
  text-align: center;
}

.page-employees-selected .td-center .select-full-employees {
  color: #3F7E38;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}