.appln_list_header,
.applicant_item .basic_info {
  display: flex;
  flex-wrap: wrap;
}
.appln_list_header .list_item_head,
.applicant_item div {
  flex: 1 0;
}
.appln_list_header .list_item_head {
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}
.appln_list_header {
  background: #eef0f2;
  padding: 13px 10px 10px;
  border: 1px solid #eef0f2;
}
.applicant_item {
  padding: 10px;
  border: 1px solid #eef0f2;
  border-top: 0;
  float: left;
  width: 100%;
  /* padding-left: 36px; */
  position: relative;
}
.applicants_list_page .applicant_item{
  padding-left: 36px;
}
.archived_applicant .applicant_item{
  padding-left: 20px;
}

.applicants_list .appln_email,
.applicants_list .appln_name {
  flex: 1 0 15% !important;
}
.applicant_item #single_note {
  margin-bottom: 5px;
  height: 40px;
  padding: 10px;
  resize: vertical;
  overflow: hidden;
  width: calc(100% - 155px);
  -o-width: calc(100% - 155px);
  -webkit-width: calc(100% - 155px);
  -moz-width: calc(100% - 155px);
  -ms-width: calc(100% - 155px);
}
.new_applicants.button {
  float: right;
  margin-bottom: 20px;
  text-decoration: none;
}
.new_applicants.button:hover,
.new_applicants.button:focus {
  color: #fff;
}
.applicants_inner_head.inner_head {
  height: 60px;
  padding: 15px 25px;
}
.applicants_inner_head.inner_head .previous_history{
  display: inline-block;
  float: right;
  margin-top: 5px;
  cursor: pointer;
}
.applicants_inner_head.inner_head .previous_history img{
  width: 17px;
  margin-right: 5px;
  margin-top: -3px;
}

.applicants_inner_head.inner_head .previous_history span{
  font-weight: 600;
  font-size: 14px;
}
.applicants_inner_head .btn_list{
  margin-top: 0px;
}



.application_history h2.previous_history{
  font-weight: 600;
  font-size: 22px;
  display: inline;
}

.application_history img{
  width: 22px;
  margin-top: -5px;
  margin-right: 10px;
  cursor: pointer;
}

.applicant-history-container{
  padding: 50px 30px;
}

.applicant-history-container .wrapper{
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  margin-top: 30px;
}

.applicant-history-container .wrapper .item > div{
  padding: 20px 0px;
  border-bottom: 1px solid #e3e3e3;
}

.applicant-history-container .wrapper .item:last-child > div{
  border-bottom: none;
}

.applicant-history-container .wrapper .item .title{
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

.applicant-history-container .wrapper .icon img{
  width: 20px;
}

.applicant-history-container .wrapper .flex-row{
  display: flex;
}

.applicant-history-container .wrapper .flex-row > div{
  flex: 0 15%;
}

.applicant-history-container .wrapper .flex-row .icon{
  flex: 0 5%;
  display: flex;
  align-items: center;
}

.applicant-history-container .wrapper .flex-row .note{
  flex: 0 65%;
}

.applicants_list .appln_name .badge{
  display: inline-block;
  padding: 6px 10px;
  font-size: 12px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #00703C
;
  color: #00703C
;
  margin-left: 5px;
}

.assign_staff_container .button {
  background-color: #00522C;
}

.assign_staff_container .button:hover {
  background-color: #003D21;
}

@media only screen and (max-width: 767px) {
  .applicants_inner_head.inner_head.inner_head_mob {
    height: 120px;
  }
}
.offer-posted {
  width: 25px !important;
  left: 9px !important;
}
#applicants_inner {
  border-radius: 0 0 3px 3px;
}
.applicant_item .actions {
  float: left;
  width: 100%;
  margin-top: 10px;
  position: relative;
}
.applicant_item .alb-info.left {
  border: 1px solid #eef0f2;
  width: calc(100% - 135px);
  -moz-width: calc(100% - 135px);
  -o-width: calc(100% - 135px);
  -webkit-width: calc(100% - 135px);
  -ms-width: calc(100% - 135px);
  padding: 5px;
  margin-right: 20px;
}
.applicant_item .actions img {
  margin: 7px 10px;
  cursor: pointer;
}
.applicants_container .top_btn_group {
  margin-bottom: 20px;
  position: relative;
  float: right;
}
.applicants_container .top_btn_group .button {
  float: right;
  margin: 0 5px;
}
.applicant_listing .page_title h1 {
  float: left;
  margin: 5px 0;
}
.applicant_listing .top_btn_group .button:first-child {
  margin-right: 0 !important;
}
.applicant_listing .page_title {
  float: left;
  width: 100%;
}

.applicant_listing .top_btn_group {
  margin: 0 !important;
}
.applicant-view-container h3 {
  font-size: 18px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  width: 50%;
}
/*.applicant_listing .applicant_search {
    width: 350px;
    float: right;
    height: 38px;
}*/
.applicant_search {
  max-width: 350px;
  float: right;
  height: 38px;
}
.applicant_filter {
  width: 350px;
  /* float: right; */
  height: 38px;
}

@media only screen and (max-width: 767px) {
  .applicant_filter {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 767px) {
  .applicant_filter {
    margin-bottom: 0;
  }
}
/** View Application - STARTS **/
.av-title {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  font-size: 17px;
}
.av-question {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  margin-bottom: 0px;
}

.col-50 {
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
}
.application_details .single-notes {
  max-width: 100%;
  min-width: 100%;
  max-height: 100px;
  min-height: 100px;
}
#avApplyingPosition p,
#avQualification p {
  margin-bottom: 0;
}
.gotoNext,
.gotoPrev {
  cursor: pointer;
}
.gotoNext span,
.gotoPrev span {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
#txtSearchUsersForForward {
  margin-bottom: 0;
}
.ForwardPopup .ReactModal__Content.ReactModal__Content--after-open {
  /* min-height: 325px; */
  max-height: calc(100vh - 100px);
}
.ForwardPopup .closeModal {
  float: right;
  margin: 15px 15px;
}
.ForwardPopup .modal-head {
  height: 50px;
}
.ForwardPopup h4 {
  width: 90%;
  float: left;
}
.ForwardPopup .closeModal {
  margin: 0;
  cursor: pointer;
}
/** View Application - ENDS **/

/** Email template -STARTS **/

.email_template {
  border: 2px solid #d8d8d8;
  min-height: 250px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: content-box;
}

.email_template .email-pen{
  width: 15px;
  margin-right: 10px;
}

.email_template:hover {
  border-color: #00703C;
}
.email_template_outer {
  margin-bottom: 20px;
  float: left;
  margin-left: -10px;
  margin-right: -10px;
}

.email_template_wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.email_template_wrapper.template4{
  margin-top: 20px;
}

#email_template_inner .button {
  float: right;
  margin-right: 15px;
}
/*.email_template.custom_email{
    display: table;
    width: 96%;
}
.email_template.custom_email p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}*/

.email_template.custom_email{
  border-style: dashed;
  width: unset;
}

.email_template.custom_email p.tmp-desc {
  text-align: center;
  padding: 65px 0;
}

.email_template .alb-info{
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
} 

.email_template.email_custom h3{
  margin: 10px 0px;
}

/** Email template -ENDS **/

/** Email Applicants -STARTS **/

.filter-block {
  width: 33%;
  padding: 0 30px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 130px;
}
.filter-block1 .filter-date {
  width: 47%;
}
.filter-block1 input {
  width: 100%;
  margin-bottom: 5px;
}
.filter-end-date input {
  /* float: right; */
}

.filter-options{
  margin-bottom : 30px;
}

.filter-options div{
  margin-right: 30px;
  display: inline;
}

/* .filter-block1,
.email_step2 .applicants_list:first-child {
  padding-left: 0;
}
.filter-block3,
.email_step2 .custom {
  padding-right: 0;
} */


.email_step1 label {
  text-align: center;
  margin-bottom: 15px;
}
.email_step2{
  margin-left: -10px;
  margin-right: -10px;
}
.email_step2 .appln_list_header {
  min-height: 235px;
  display: table;
  border-radius: 3px;
  width: 100%;
}
.email_step2 .custom{
  margin-top:20px;
}
.email_step2 .custom p {
  text-align: center;
  max-height: 200px;
  overflow-y: scroll;
}

.email_step2 .applicants_list{
  padding-left: 10px;
  padding-right: 10px;
}

.email_step2 .custom.appln_list_header p {
  display: table-cell;
  vertical-align: middle;
}
.email_template_outer .email_template h3,
.email_step2 .email_template h3 {
  font-size: 14px;
  text-align: center;
  margin: 10px 0 15px;
}
.email_step2 .appln_list_header p {
  text-align: justify;
}
.email_step2 .custom_p p {
  line-height: 145px;
  text-align: center !important;
}
.custom_template_mail h4 {
  text-align: left;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  font-size: 16px;
  margin: 0;
}
.custom_template_mail p.step_no {
  margin-top: -10px;
}
.custom_template_mail .btn_list {
  float: none;
}
.filter-block-half {
  width: 50%;
  height: 130px;
}
.efinalwrapfilters.efinalwrap {
  width: auto;
  float: right;
  padding-right: 30%;
}
.email_step3 h4 {
  font-size: 14px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.filter_outer.content_div {
  padding-top: 20px;
}
.active .email_template,
.active .rmOption {
  /* border-color: rgba(63,126,57,0.55); */
  border-color: #00703C;
  border-width: 3px;
  background: #eef0f2;
}
/** Email Applicants -ENDS **/
.applicant_item .noResult {
  text-align: center;
  width: 100%;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  padding-top: 15px;
}
.filter_container .email_step3 .filter-block-half {
  height: auto;
}
.filter_container .email_step3 .filter-block-half .email_template {
  min-height: 165px;
}
.rmOption:hover {
  border-color: #00703C;
  border-width: 3px;
  cursor: pointer;
}
#avTechSchool1 p,
#avTechSchool2 p,
#avProfessionalReference2 p,
#avProfessionalReference1 p,
#avProfessionalReference3 p,
#avLicense p,
#avReference1 p,
#avReference2 p,
#avReference3 p {
  margin-bottom: 0;
}
.application_details .view_inner_p {
  margin-bottom: 10px !important;
}
.archived_applicant .changeType {
  float: right;
  width: 320px;
  height: 35px;
  padding: 0 10px;
}
.applicant_listing .list_item_head .sort-arrow,
.hired_applicants .sort-arrow,
.archived_applicant .sort-arrow {
  cursor: pointer;
  width: 0px;
  display: inline-block;
  height: 0px;
  margin: 2px 5px;
  border-style: solid;
  line-height: 16px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #000000 transparent;
  opacity: 0.2;
}
.applicant_listing .list_item_head .sort-arrow.desc,
.hired_applicants .sort-arrow.desc,
.archived_applicant .sort-arrow.desc {
  opacity: 1;
  /*border-width: 5px 5px 0 5px;*/
  border-width: 0 5px 5px 5px;
  /*border-color: #000000 transparent transparent transparent;*/
  border-color: transparent transparent #000000 transparent;
}

.applicant_listing .list_item_head .sort-arrow.asc,
.hired_applicants .sort-arrow.asc,
.archived_applicant .sort-arrow.asc {
  opacity: 1;
  /*border-width: 0 5px 5px 5px;*/
  border-width: 5px 5px 0 5px;
  /*border-color: transparent transparent #000000 transparent;*/
  border-color: #000000 transparent transparent transparent;
}
.applicant_listing .inner_container,
.hired_page .inner_container,
.archived_page .inner_container {
  width: 100%;
}
.rmOptionWrap.borderError .rmOption,
.email_template_wrapper.borderError .email_template,
.email_step2 .applicants_list.borderError .email_template {
  border-color: red;
}
/* .RemoveApplntPopup .ReactModal__Content.ReactModal__Content--after-open {
  max-height: 310px;
} */
.rmOptionBox .rmOptionWrap {
  float: left;
  /* width: 33%; */
}
/*.rmOptionBox .rmOption{
    line-height: 20px;
}*/
/* .rmOptionWrap:first-child .rmOption {
  line-height: 40px !important;
} */
#txtDate.error {
  border-color: #ff0000 !important;
  background: #ffdcdc !important;
}
.application_details h3.right {
  text-align: right;
}
.application_details .breadcrumbs {
  margin-bottom: 25px;
}
#applicants_list .applicant_item:nth-child(odd) {
  background: #f1f1f1;
}

#applicants_list .applicant_item:nth-child(even) {
  background: #fff;
}
.aplication-status-indicator {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 12px;
  top: 8px;
}
.aplication-status-indicator img {
  width: 100%;
}

.applicants_list_page .appln_list_header{
  padding-left: 36px;
}
.archived_applicant .appln_list_header{
  padding-left: 20px;
}
.list_item_head.appln_name {
  /* padding-left: 40px !important ; */
}

.av-empty {
  display: block;
}

.applicants_list .appln_email,
.applicants_list .appln_name {
  -ms-flex: 1 0 1% !important;
  flex: 1 0 1% !important;
}

.in-review-ico {
  max-width: 15px;
}

.rmOptionBox {
  padding: 15px 0 20px;
  display: flex;
  flex-direction: row;
}

.rmOptionBox .rmOptionWrap {
  float: left;
  /* width: 33%; */
  display: flex;
  float: none;
  flex: auto;
  margin: 0 8px;
}

.rmOption {
  width: 100%;
  box-shadow: 0 0 0px 1px #c2c3c2;
  border: solid 3px transparent !important;
  padding: 15px 15px !important;
}
.rmOption:hover {
  border-color: #00703C !important;
  box-shadow: none !important;
}
@media screen and (max-width: 600px){
  .rmOption:hover {
    border-color: #eef0f2 !important;
  }
}
.active.rmOptionWrap .rmOption {
  border-color: #00703C !important;
  box-shadow: none !important;
}

.rmOptionBox .rmOptionWrap {
  margin-bottom: 15px;
}
.rmOptionBox {
  flex-wrap: wrap;
}

@media screen and (max-width: 1250px) {
  .rmOptionBox {
    flex-wrap: wrap;
  }

  .rmOptionBox .rmOptionWrap {
    margin-bottom: 15px;
  }

  .applicant-history-container .wrapper .flex-row{
    display: flex;
  }
  
  .applicant-history-container .wrapper .flex-row > div{
    flex: 0 20%;
  }
  
  .applicant-history-container .wrapper .flex-row .icon{
    flex: 0 8%;
  }
  
  .applicant-history-container .wrapper .flex-row .note{
    flex: 0 55%;
  }

  .applicants_list .appln_name .badge{
    display: block;
    padding: 4px 6px;
    max-width: 45px;
    margin-left: 0px;
    margin-top: 5px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 981px) {
  .rmOptionBox .rmOption {
    line-height: 40px !important;
  }

  .applicant-history-container .wrapper .flex-row{
    display: block;
  }
  
  .applicant-history-container .wrapper .flex-row > div{
    flex: 0 20%;
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 15px;
  }
  
  .applicant-history-container .wrapper .flex-row .icon{
    flex: 0 8%;
    margin-bottom: 10px;
  }

  .applicant-history-container .wrapper .icon img {
    width: 25px;
  }
  
  .applicant-history-container .wrapper .flex-row .note{
    flex: 0 55%;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .applicant-history-container .wrapper {
    margin-top: 0px;
  }

  .application_history h2.previous_history {
    font-size: 18px;
    display: block;
  }
}
.applicants_list_page .appln_date {
  max-width: 100%;
  /* background: #f00; */
  padding: 0 5px;
}
.applicants_list_page .appln_area {
  max-width: 192px;
  padding: 0 5px;
}
.applicants_list_page .appln_phone {
  max-width: 100%;
}
@media screen and (max-width: 480px) {
  .applicants_list_page .appln_phone,
  .applicants_list_page .appln_area,
  .applicants_list_page .appln_date {
    max-width: 100%;
  }

  .applicants_list .appln_name .badge{
    margin-left: auto;
    margin-right: auto;
  }
}


.customised-pagination .pagination li a {
  color: #000!important;
  background-color: transparent!important;
  border: none!important ;
  outline: none !important ;
}

.customised-pagination .pagination li.active a {
  color: #00703C!important;
  text-decoration: underline!important
}

.customised-pagination .pagination li.next,.customised-pagination .pagination li.previous {
  width: 27px;
  height: 27px;
  /* border: solid 1px #c5c5c5; */
  display: inline-block;
  float: left;
  border-radius: 50%
}
.customised-pagination .pagination li.previous a{
  background-image: url(../images/previous.svg);
  background-size: 26px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 27px;
  height: 27px;
}
.customised-pagination .pagination li.next a{
  background-image: url(../images/next.svg);
  background-size: 26px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 27px;
  height: 27px;
}
.customised-pagination .pagination li.disabled{
  opacity: .5;
}
.items-wrapper{
  display: block ;
  position: relative;
}
.items-overlay{
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255,255,255,.5);
  z-index: 1;
}

.results_per_page_wrapper{
  display: flex ;
  flex-direction: row ;

  
}
.results_per_page_wrapper select{
  width:65px;
}

.right_inputs_wrapper{
  display: flex ;
}

.right_inputs_wrapper .results_per_page_wrapper{
  display: flex ;
  flex: auto ;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;
  position: relative;
    top: -10px;
}

.right_inputs_wrapper .applicant_search_wrapper{
  display: flex ;
}

.right_inputs_wrapper select{
  margin-bottom: 0 !important;
}
.right_inputs_wrapper select{
  height:36px;
  margin-left: 10px;
}

.light-box{
  background-color: #fff !important ;
}

.applicants_container .email_template_outer {
  width: 100%;
}

.styles_modal__gNwvD {
  /* top: 0px !important; */
  margin-bottom: 100px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.styles_overlay__CLSq-, .ReactModal__Overlay {
  overflow: auto;
}

textarea, input, a {
  outline: none;
}

@media screen and (max-width:767px){
  .results_per_page_wrapper{
    /* flex-wrap: wrap ; */
    margin-right: 20px !important;
  }
}

.hide-filters{
  display: none !important ;
}
@media screen and (max-width:868px){
  .applicants_actions_wrapper{
    width: 100% !important;
  }

  .filter-options div{
    margin-right: 0px;
    margin-bottom: 10px;
    display: block;
  }

}

@media screen and (max-width:767px){
  .applicants_actions_wrapper{
    width: 100% !important;
    padding-top: 45px !important;
    text-align: center;
  }
}

@media screen and (max-width: 530px)
{
  .resource_container .applicants_inner_head .btn_list .button {
      width: unset;
      text-align: center;
      margin-left: 15px;
  }
}




/* April 2022 */

.applicants_container .applicant_hdr_btn_grp {
  display:flex;
  justify-content: space-between;
  padding: 0 20px;
}
@media only screen and (max-width: 496px) {
  .applicants_container .applicant_hdr_btn_grp {
    flex-direction: column;
  }
}
.applicants_container .applicant_hdr_btn_grp h3{
  font-size: 18px;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  width: 50%;
}

.applicants_container .top_btn_group.new_btn_grp {
  margin: 15px 5px 0 0 !important;
}


/* April 2022 */



/* ToolTip: Interview form not filled */

.applicant_no_interview_forms_icon .applicant_no_interview_forms_tooltip {
  visibility: hidden;
  width: 250px;
  background-color: black !important;
  color: #fff !important;
  text-align: center;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 10px;
  top: -30px;
}



.applicant_no_interview_forms_icon {
  position: absolute;
  left: -35px;
  top: 50%;
  transform: translateY(-60%);
}



.applicant_no_interview_forms_icon:hover .applicant_no_interview_forms_tooltip {
  visibility: visible;
}



.applicant_no_interview_forms_tooltip::after {
  border: solid 5px #171717;
  content: "";
  position: absolute;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -10px;
  left: 5px;
}



@media only screen and (max-width: 767px) {
  .applicant_no_interview_forms_icon {
    top: 30% !important;
  }
}



/* End */

.applicant_history_container {
  padding: 16px 0px !important;
  display:inline-flex;
  align-items: center;
  width: 100% !important;
}

@media only screen and (max-width: 767px) {
  .applicant_history_container {
   justify-content: center;
  }
}




.applicant_history_text {
  margin: 0 !important;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: rgba(32, 32, 32, 0.6);
  padding-right: 5px;
  padding-top: 3px;
}

.applicant_history_icon{
  width: 12px;
}

.application_history_alert_container{
  padding: 14px 16px;
  background-color: #FEFCE7;
  margin-top: 10px;
}

.application_history_alert_text {
  text-align: left;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
}