.content_div {
    float: left;
    width: 100%;
    background: #fff;
    padding: 0 15px 15px;
    border-radius: 0px 0px 3px 3px;
    box-shadow: 6px 7px 10px -4px #DFE0E2;
}
.avail_btn{
    background: #51DD76;
    border:0;
    color: #fff;
    font-size: 12px;
    width: 80%;
    max-width: 181px;
}

.settings .profile_pic{
    width: 80%;
    max-width: 181px;
}
.settings .profile1 .crew-profile-detail {
    margin-top: 20px;
    text-align: center;
}
.settings .crew-profile-detail p {
    margin-bottom: 3px;
}
.settings .profile1{
    /*width: 20%;*/
    text-align: center;
    float: left;
}
.content_div.settings {
    padding-top: 30px;
}
.profile2.cp-profile-info {
    float: left;
    /*width: 45%;*/
    border-right: 2px solid #D8D8D8;
    border-left: 2px solid #D8D8D8;
    padding: 0 20px;
}
.settings .profile2 h4.cp-title {
    font-size: 12px;
    font-family: 'Proxima Nova Semibold', Georgia, sans-serif;
    color: #B1B2B4;
    margin-bottom: 5px;
}
.cp-qual p.crew-profile-detail {
    margin-bottom: 1px;
}
.profile3 {
    /*width: 35%;*/
    padding-top: 80px !important;
    float: left;
    padding: 80px 0px 0 20px;
}
.single-notes-cp {
    border: 1px solid #D0D3D4;
    height: 241px;
    width: 315px;
    max-width: 100%;
    max-height: 214px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*font-size: 16px;*/
    line-height: 20px;
    border-radius: 3px;
    margin-bottom: 30px;
}
.profile3 h4.cp-title {
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
    font-size: 14px;
}
.gear_total {
    margin-bottom: 30px;
}
.gear_total label {
    color: #B1B2B4;
    font-size: 12px;
    font-family: 'Proxima Nova Semibold', Georgia, sans-serif;
    width: 100%;
}
.legal-agreement{
    margin-bottom: 20px;
}
.legal-agreement .green:hover{
    color: #3F7E39;    
    font-family: 'Proxima Nova Semibold', Georgia, sans-serif;
}
.show_popupAssignGear{
    padding: 7px 20px;
}
.show_popupAssignGear:hover {
    color: #fff;
    text-decoration: none;
}
.profile-sep {
    margin-bottom: 20px;
}
.profile-sep p {
    margin-bottom: 0;
    text-transform: capitalize;
}
.button.edit_profile {
    float: right;
    margin-top: -15px;
    margin-right: 20px;
    cursor: pointer;
}
button[disabled].button.edit_profile, button[disabled].button.edit_resource  {
    background-color: #1f802e87;
    pointer-events: none;
}
  
.settings_container .button.edit_profile{
    margin-right: 0;
    margin-top: 0px;
}
span.emp_status {
    width: 10px;
    height: 10px;
    background: #E6E432;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}
.settings_container .inner_head h2 {
    float: left;
    border-right: 1px solid #D8D8D8;
    cursor: pointer;
    color: #B1B2B4;
}
.settings_container .inner_head h2:last-child{
    border-right: 0;
}
.settings_container h1{
    display: inline-block;
    float: left;
    font-size: 24px;
    margin-bottom: 15px;    
}
.settings_container .page_title {
    padding-bottom: 0;
}
.settings_container .btn_list {
    margin-top: 15px;
}
.settings_container h2.active{
    color: #202020;
}

/** Bulletin  - STARTS **/
.new_bulletin{
    width: 700px;
    padding-top: 80px !important;
    margin: 0 auto;
    padding: 0 20px;
}
.bulletin_txt{
    height: 236px;
    min-height: 236px;
    max-height: 236px;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 17px;
}
#attachFilePopup .button {
    float: right;
    margin-bottom: 20px;
}
.new_bulletin .bulletin_imgs{
    margin-top: 0;
    margin-bottom: 25px;
}
.new_bulletin .bulletin_imgs img {
    margin-right: 15px;
    float: left;
    width: 100px;
}
.create_bulletin {
    float: left;
    /*border-bottom: 1px solid #D8D8D8;*/
    padding-bottom: 20px;
    margin-bottom: 20px;
    max-width: 100%;
}
/*.bulletin_item .green{
    width: 100px;
    height: 100px;
    background-size: 75px !important;
    margin: 0 auto;    
}
.bulletin_item {
    width: 112px;
    float: left;
    margin: 0 10px;
    text-align: center;
    position: relative;
}
.bulletin_item img.close_btn {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px !important;
    cursor: pointer;
}*/
.bulletin_item {
    width: 100%;
    float: left;
    border-bottom: 1px solid #D8D8D8;
    margin-top: 5px;
}
.bulletin_item:first-child {
    border-top: 1px solid #D8D8D8;
    margin-top: 10px;
    padding-top: 5px;
}
.bulletin_item .green, .home_bulletin .bulletin_link_item .green,
.home_bulletin .bulletin_link_item .green, .home_bulletin .bulletin_imgs_item .green{
    width: 80px;
    height: 80px;
    background-size: 55px !important;
    margin: 0 auto;
    float: left;   
}
.bulletin_item .title{
    line-height: 90px;
    text-transform: capitalize;
    margin-bottom: -5px;
}
.bulletin_item img.close_btn {
    float: right;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
    margin-top: 32px;
}
.attach_head{
    font-size: 16px;
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
    margin: 0;
    padding-bottom: 5px;
}
.bulletin_item .pdf, .home_bulletin .bulletin_link_item .pdf{
    background: url(../images/pdf.png) no-repeat center;
}
.bulletin_item .avi, .home_bulletin .bulletin_imgs_item .avi{
    background: url('../images/avi.png') no-repeat center;
}
.bulletin_item .css, .home_bulletin .bulletin_imgs_item .css{
    background: url('../images/css.png') no-repeat center;
}
.bulletin_item .doc, .home_bulletin .bulletin_link_item .doc{
    background: url(../images/doc.png) no-repeat center;
}
.bulletin_item .eps, .home_bulletin .bulletin_imgs_item .eps{
    background: url('../images/eps.png') no-repeat center;
}
.bulletin_item .mov, .home_bulletin .bulletin_imgs_item .mov{
    background: url('../images/mov.png') no-repeat center;
}
.bulletin_item .mp3, .home_bulletin .bulletin_imgs_item .mp3{
    background: url('../images/mp3.png') no-repeat center;
}
.bulletin_item .php, .home_bulletin .bulletin_imgs_item .php{
    background: url('../images/php.png') no-repeat center;
}
.bulletin_item .png, .home_bulletin .bulletin_imgs_item .png{
    background: url('../images/png.png') no-repeat center;
}
.bulletin_item .ppt, .home_bulletin .bulletin_imgs_item .ppt{
    background: url('../images/ppt.png') no-repeat center;
}
.bulletin_item .psd, .home_bulletin .bulletin_imgs_item .psd{
    background: url('../images/psd.png') no-repeat center;
}
.bulletin_item .rar, .home_bulletin .bulletin_imgs_item .rar{
    background: url('../images/rar.png') no-repeat center;
}
.bulletin_item .txt, .home_bulletin .bulletin_imgs_item .txt{
    background: url('../images/txt.png') no-repeat center;
}
.bulletin_item .wav, .home_bulletin .bulletin_imgs_item .wav{
    background: url('../images/wav.png') no-repeat center;
}
.bulletin_item .zip, .home_bulletin .bulletin_imgs_item .zip{
    background: url('../images/zip.png') no-repeat center;
}
.bulletin_item .jpg, .home_bulletin .bulletin_imgs_item .jpg,
.bulletin_item .jpeg, .home_bulletin .bulletin_imgs_item .jpeg{
    background: url('../images/jpg.png') no-repeat center;
}
.content_div.bulletin {
    margin-bottom: 20px;
}
.list_bull_item{
    display: flex;
    border: 1px solid #EEF0F2;
    flex-wrap: wrap;
    border-bottom: 0;
    padding: 5px 10px;
}
.list_bull_item:last-child{
    border-bottom: 1px solid #EEF0F2;
}
.list_bull_item div{
    /*flex: 1 0 20%;*/
}
.list_bull_item img.close_btn.bull_cell {
    width: 21px;
    height: 20px;
    margin: 10px 0;
    cursor: pointer;
}
.list_bull_item div.bull_cell {
    padding: 10px;
}
.list_bull_item button.pinPost {
    padding: 0 10px;
    height: 25px;
    margin: 8px 20px;
    min-width: 115px;
    color:#ffffff;
}
.list_bull_item .title.bull_cell{
    width: 20%;
}
.list_bull_item .message.bull_cell{
    width: 52%;
    word-break: break-word;
}
.list_bull_item .action.bull_cell{
    width: 20%;
}
.list_bull_item .action .bull_cell{
    color:#ffffff;
}
.list_bulletin h2{
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
    font-size: 18px;
}
.bulletin_outer_div {
    width: 100%;
    padding-bottom: 20px;
    float: left;
    margin: 30px auto;
    border: 1px solid #F7F9FA;
}
.bulletin_tabs div {
    float:  left;
    border-right: 1px solid #D8D8D8;
    padding: 5px 15px;
    cursor: pointer;
}
.bulletin_tabs div:last-child {
    border-right: 0;
}
.bulletin_tabs div:hover {
   /*.list_bull_item img.close_btn.bull_cellfont-family: 'proxima_nova_altbold', Georgia, sans-serif; */
}
.bulletin_tabs {
    background: #F7F9FA;
    float: left;
    width: 100%;
    padding: 10px 0;
}
.content_div.bulletin.settingsBull {
    padding: 0px 50px;
}
.list_bulletin {
    padding: 20px 20px 3px;
}
.settingsBull .bulletin_tabs .active{
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
}
.opt-msg-select.bulletin .opt-email.opt-select, 
.opt-msg-select.bulletin .opt-sms.opt-select  {
    float: left;
    width: auto;
}

.opt-msg-select.bulletin label {
    float:  left;
    width:  auto;
    margin: 0 25px 0 5px;
}
.settingsBull .list_bull_item .editPin.button.bull_cell {
    padding: 3px 20px;
    min-width: 55px;
}
/** Bulletin  - ENDS **/

/** Permission - STARTS **/

.content_div.permissions {
    padding: 20px 20px;
}

#permission-tbl th {
    background: #EEF0F2;
    padding: 15px 15px;
    flex: 1 0;
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
    text-align: center;
}
@media screen and (max-width: 1200px){
    #permission-tbl th {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}
@media screen and (max-width: 992px){
    #permission-tbl th {
        font-size: 11px !important;
    }
    #permission-tbl tr td {
        font-size: 11px !important;
    }
}
#permission-tbl {
    width: 100%;
}
#permission-tbl tr {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #EEF0F2;
    border-bottom: 0;
}
#permission-tbl tr:last-child {
    border: 1px solid #EEF0F2;
}
#permission-tbl tr td{
    padding: 15px 15px 10px;
    flex: 1 0;
    text-align: center;
}
#permission-tbl tr td.title, #permission-tbl tr th.title {
    text-align: left;
    flex: 1 0 10%;
    word-break: break-all;
}
#permission-tbl tr.parent td{
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
}
#permission-tbl tr.child td:first-child {
    padding-left: 35px !important;
}
#permission-tbl tr.parent td:first-child {
    padding-right: 35px;
}
#permission-tbl input[type="checkbox"] + label span,
.current_qualification_item input[type="checkbox"] + label span,
.edit_app_container input[type="checkbox"] + label span{
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: -2px 10px 0 0;
    vertical-align: middle;
    background: url(../images/unchecked.png) center no-repeat;
    cursor: pointer;
}
label.chk_label{
    width: auto;
}
.edit_app_container .checkbox_div {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}
#permission-tbl input[type="checkbox"],
.current_qualification_item input[type="checkbox"],
.edit_app_container input[type="checkbox"]{
    display: none;
}
#permission-tbl input[type="checkbox"]:checked + label span,
.current_qualification_item input[type="checkbox"]:checked + label span,
.edit_app_container input[type="checkbox"]:checked + label span{
    background: url(../images/tick.png) center no-repeat;
}
#permission-tbl label {
    float: none;
}
#permission-tbl input[type="checkbox"].disabled+ label span {
    cursor: not-allowed;
    opacity: .3;
}
.assign-admin-container .admin-list div:first-child {
    border-top: 1px solid #EEF0F2;
}
.assign-admin-container .admin-list div {
    border-bottom: 1px solid #EEF0F2;
    padding: 5px;
    float: left;
    width: 100%;
}
.assign-admin-container div img {
    float: left;
    margin-right: 10px;
    width: 45px;
    height: 45px;
}
.admin-list .user-name {
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
}
.assign-admin-container .admin-list p {
    padding: 15px 0 0;
}
.assign-admin-container .remove-admin {
    background: url(../images/close_button.png) no-repeat;
    background-size: 21px;
    width: 21px;
    height: 21px;
    float: right;
    cursor: pointer;
}
.assign_admin_outer h3{
    color: #00703C;
    padding-bottom: 5px;
    text-transform: uppercase;
}
.assign_admin_outer {
    padding: 20px 0px;
}
.assign-admin.button {
    float: right;
    margin-top: 20px;
}
#unassignPopup{
    padding: 55px 40px 40px;
    text-align: center;
}
#unassignPopup .button {
    margin: 0 5px;
}
#unassignPopup p{
    margin-bottom: 30px;
}
#allAdminPopup {
    padding: 25px 40px 40px;
}
#allAdminPopup label{
    text-align: left;
}
#allAdminPopup li.user_click {
    cursor: pointer;
    float: left;
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    height: 50px;
}
#allAdminPopup li.user_click:nth-child(odd) {
    background: #e7eae6;
}
#allAdminPopup .user_click img{
    width: 35px;
    height: 35px;
    float: right;
    margin-right: 5px;
    margin-top: 2px;
}
#allAdminPopup #searchResultTop {
    padding: 0;
    list-style-type: none;
    background: #fff;
    border: 1px solid #979797;
    /*max-height: 300px;*/
    overflow-y: auto;
}
#allAdminPopup input#txtSearchUsersForAssign {
    margin-bottom: 0;
}
#allAdminPopup .user_click span{
    float: left;
    padding: 10px 0;
}
.UnAssignAdminPopup .closeModal {
    float: right;
    margin: 15px 15px;
}
.UnAssignAdminPopup .ReactModal__Content.ReactModal__Content--after-open{
    height: 325px;
}
#allAdminPopup p {
    float: left;
    width: 100%;
    text-align: left;
    padding: 10px 0;
}
/* 
- - - Responsivise at breakpoint - - - - */
@media (max-width: 600px) {
    /* make tds into single column full width rows */
    #permission-tbl  tr,td {
        display: block;
        width: 100%;
        padding: 5px;
    }  
  
    /* hide table head */
    #permission-tbl thead {
        display: none;
    }

    /* place data attribute before td as a label */
    #permission-tbl td[data-header-title]:before {
        content: attr(data-header-title)':';
        display: block;
        color: #666;  
        /* labels will stack by default but optionally float to left */
        float:left;
        width:100px;
    }
    #permission-tbl tbody {
        border-collapse: collapse;
        display:block;
    }
    #permission-tbl tbody tr {
        margin-bottom:20px;
        border-bottom: 10px solid #222222;
        display:block;
    }
      /* unset background used on desktop view */
     #permission-tbl tbody tr:nth-of-type(odd) {
        background: transparent;
      }
      #permission-tbl tr td:nth-of-type(odd) {
          background:#eee;
      }
      #permission-tbl tr{
        display: block;
      }
}
/** Permission - ENDS **/

/*** Edit status - STARTS ****/

.status_step label{
    margin-top: 25px;
    font-size: 15px;
    float: none;
}
.status_step label .green{
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
    font-size: 15px;
}
.status_step .button {
    margin: 25px 0;
}
.status_step .button.btn_margin {
    margin-left: 12px;
}
.status_step p.step_no {
    margin-bottom: 25px;
    color: #B1B2B4;
    font-size: 12px;
    font-family: 'Proxima Nova Semibold', Georgia, sans-serif;
}
.status_step.step2 label {
    width: 100%;
}
.status_step.step2 .button, .status_step.step5 .button {
    margin: 25px 5px;
}
.status_step.step4 .react-datepicker-wrapper {
    width: 65%;
}
.status_step.step4 .react-datepicker__input-container {
    width: 100%;
}
.status_step.step4 label {
    margin-bottom: 25px;
}
.status_step.step4 input.returnDate {
    margin-bottom: 5px;
}
.status_step.step4 .react-datepicker__input-container input {
    margin-bottom: 0;
}
.status_step.step5 label {
    width: 100%;
}
.status_step.step5 p {
    color: #B1B2B4;
    font-size: 14px;
}
/*** Edit Status - ENDS ***/

/** Edit Profile - STARTS **/

.content_div.profile_edit {
    padding: 15px 0 15px;
    margin-bottom: 25px;
}

@media screen and (max-width: 480px){
    .content_div.profile_edit .btn_list{
        padding-right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .content_div.profile_edit .btn_list button.button{
         margin-right: 10px;
    }
}


.profile_edit_wrapper .formField,
.label_bar.more .formField,
.label_bar.physicalAddress .formField{
    width: 46%;
}
.label_bar.current_qualification .formField {
    width: 100%;
}
.label_bar{
    float: left;
    width: 50%;
}
.label_bar .formField{
    width: 100%;
}
.label_bar h2{
    background: #F7F9FA;
    padding: 15px; 
    font-size: 16px;
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
}
.label_bar.more, .label_bar.physicalAddress{
    width: 100%;
}
.profile_edit .btn_list {
    padding-right: 20px;
}
.current_qualification .formField{
    padding: 10px 20px 0px; 
}
.profile_edit input[type=checkbox] {
    float: left;
    margin-right: 10px;
    width: auto;
}
.profilePhoto {
    padding: 20px;
}
.profilePhoto img.profile_pic {
    width: 181px;
}
.current_qualification_item {
    float: left;
    width: 100%;
    margin: 5px 0;
}
.current_qualification_item label {
    width: auto;
}
span.sameAddress {
    float: left;
    padding: 20px;
}
.profile_edit .react-datepicker__input-container, .profile_edit .react-datepicker-wrapper{
    width: 100%;
}
.upload_or_drag.edit_profile_pic {
    width: 181px;
    height: 181px;
    margin: 20px;
    padding: 0;
}
.upload_or_drag.edit_profile_pic div:first-child{
    height: 181px !important;
    line-height: normal;
    border: 0 !important;
    position: relative;
    padding: 0;
}
.upload_or_drag.edit_profile_pic img.profileImg{
    top: 0;
    width: 177px;
    height: 177px;
    left: 0;
    position: absolute;
    object-fit: cover;
}
.upload_or_drag.edit_profile_pic p {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-family: 'Proxima Nova Semibold', Georgia, sans-serif;
    width: 100%;
    text-align: center;
}
.upload_or_drag.edit_profile_pic p:hover {
    cursor: pointer;
}

.imageCropPopup .edit_upload_pic.button {
    display: inline-block;
    margin-left: 10px;
    width: 105px;
}
.imageCropPopup .edit_upload_pic.button label{
    display: inline-block;
    float: left;
    margin: 0;
    width: auto;
    color: #fff;
}
.imageCropPopup .edit_upload_pic.button .file{
    visibility: hidden;
    margin-bottom: 0;
    padding: 0;
    width: 10px;
}
.edit_profile_pic_popup .button_list{
    margin-bottom: 15px;
}
.edit_profile_pic_popup .button_list div#file_name{
    height: 36px !important;
}
.edit_profile_pic_popup .button_list div#file_name p{
    line-height: 23px;
    color: #fff;
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
}
.imageCropPopup .popup_upload{
    display: inline-block; 
    margin-right: 10px;
}
.imageCropPopup .popup_upload p{
    margin: 0; 
    color: #fff;
    font-family: 'proxima_nova_altbold', Georgia, sans-serif;
}
/** Edit Profile - ENDS **/



.bulletin_link_item .txt {
  background: url("../images/txt.png") no-repeat center;
}

.bulletin_item .gif,
.home_bulletin .bulletin_imgs_item .gif{
  background: url("../images/gif.png") no-repeat center;
}



/* Qualification Table */


#permission-tbl input[type="checkbox"]+label span,
.current_qualification_item input[type="checkbox"]+label span,
.mobile_qualification_item input[type="checkbox"]+label span,
.edit_app_container input[type="checkbox"]+label span {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: -2px 10px 0 0;
    vertical-align: middle;
    background: url(../images/unchecked.png) center no-repeat;
    cursor: pointer;
}

#permission-tbl input[type="checkbox"],
.current_qualification_item input[type="checkbox"],
.mobile_qualification_item input[type="checkbox"],
.edit_app_container input[type="checkbox"] {
    display: none;
}

#permission-tbl input[type="checkbox"]:checked+label span,
.current_qualification_item input[type="checkbox"]:checked+label span,
.mobile_qualification_item input[type="checkbox"]:checked+label span,
.edit_app_container input[type="checkbox"]:checked+label span {
    background: url(../images/tick.png) center no-repeat;
}

.qualification_table {
    width: 48.8%;
}

.qualification_table th {
    padding: 12px !important;
    cursor: pointer;
}

.qualification_table_row {
    background: #f6f6f6;
    border: 1px solid #eef0f2;
}

.qualification_table_tbody tr {
    border: 1px solid #eef0f2;
}

.qualification_table_tbody td {
    padding: 12px;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.qualification_table_tbody td input {
    margin-bottom: 0px;
    height: 32px;
}

.qualification_table_tbody input:disabled {
    background: #f9f9f9;
}

.qualification_table_tbody td input {
    margin-bottom: 0px;
    height: 32px;
}

.mobile_qualification_item {
    float: left;
    width: 60%;
    margin: 5px 0;
}

.mobile_qualification_item label {
    width: auto;
}

.mobile_qualification_item input {
    margin-bottom: 0px;
    height: 32px;
}