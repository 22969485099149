.dashboard-dynamic {
  width: 100%;
  display: block;
  float: left;
  min-height: 100vh;
  display: flex ;
  flex-direction: row ;
}

.drop-block {
  display: block;
  width: 100%;
  padding: 2px 0;
  padding-bottom: 2px !important ;
  float: left;
  height: 100% ;
  min-height: 100% ;

}

.dragging-over{
  background-color: #e6e6e6;
  padding: 12px 12px 50px;
  border-radius: 5px;
}
.toggle-controller {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(../images/downarrow.png);
  background-repeat: no-repeat;

  background-position: center;
  background-color: transparent;
  cursor: pointer;
  background-size: 21px;
}
.draggable-box {
  float: left;
  width: 100%;
  height: auto;
  background-color: rgb(236, 236, 236);
}
.dashboard-loader {
  text-align: center;
  padding: 0;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-dynamic [data-react-beautiful-dnd-drag-handle] {
  cursor: initial !important;
}
.inventory_outer:hover .drag-controller,
.inventory_outer:focus .drag-controller,
.inventory_outer:active .drag-controller,
.bulletin:hover .drag-controller,
.bulletin:focus .drag-controller,
.bulletin:active .drag-controller,
.employee_stats_outer:hover .drag-controller,
.employee_stats_outer:active .drag-controller,
.employee_stats_outer:focus .drag-controller {
  opacity: 1;
}
.employee_stats.inner_head,
.bulletin_head.inner_head,
.inner_head {
  position: relative;
}

.inventory_outer + div {
  background-color: aqua;
  height: 500px;
}
.employee_stats_outer {
  display: block;
  width: 100%;
  float: left;
}
.drop-container{
  padding-bottom: 50px;
  display: flex ;
}

.drop-container .drop-block > div {
  float: left;
  clear: both;

}

.loyee_stats_details {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important ;
}

.drop-container .drop-block .draggable-box {
  /* margin-bottom: 25px; */
}
.bulletin {
  float: left;
  width: 100%;
}
.employee_stats_outer,
.inventory_outer,
.bulletin {
  margin-bottom: 25px;
}
.applicant_stats_details[aria-expanded="false"],
.crew_details.content_div[aria-expanded="false"],
.inventory_details[aria-expanded="false"],
.employee_stats_details[aria-expanded="false"] {
  display: none;
}
.applicant_stats_details[aria-expanded="true"],
.crew_details.content_div[aria-expanded="true"],
.inventory_details[aria-expanded="true"],
.employee_stats_details[aria-expanded="true"] {
  display: block;
}

.bulletin_div.home_bulletin[aria-expanded="false"] {
    max-height: 215px;
  overflow: hidden;
  position: relative;
}
.bottom-fade-overlay {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7c7c7c+0,ffffff+100&0+0,1+100 */
 /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+1,1+100 */
background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50px;
  cursor: pointer;
}

.bullettin-plus-icon{
    display: inline-block ;
    /* background-image: url(../images/add-new.png); */
    width: 28px;
    height: 31px;
    background-size: 22px;
    background-repeat: no-repeat;
    position: absolute;
    margin-left: 0px;
    background-position: center;
    top: 0;
    bottom: 0;
    margin: auto 0 auto 10px;
    cursor: pointer;
}
.bullettin-plus-icon a{
    display: block ;
    width: 100% ;
}
.bullettin-plus-icon img{ 
    width: 100% ;
    height: auto ;
}
.bulletin_div.home_bulletin[aria-expanded="false"] .bottom-fade-overlay {
  display: block;
}
.bulletin_div.home_bulletin[aria-expanded="true"] .bottom-fade-overlay {
  display: none;
}
.bulletin_div.home_bulletin[aria-expanded="true"] {
  max-height: initial;
  overflow: initial;
}
.drop-block [data-react-beautiful-dnd-draggable] {
  outline: none;
  background-color: transparent;
}

.drag-icon-controller {
  background-image: url(../images/drag.png);

  width: 37px;
  /* height: 20px; */
  /* display: inline-block; */

  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 3px;
  margin: auto;
  display: none;

  cursor: move;
    position: absolute;
    background-color: rgba(255,255,255,.9);

    top: 0;
    bottom: 0;
    left: 0;
}

.dashboard-dynamic .inner_head:hover h2 {
  /* padding-left: 30px; */
  /* cursor: move; */
}

.inner_head:hover .drag-icon-controller {
  display: inline-block;
}


@media (max-width: 767px){
  .dashboard-dynamic {
    flex-wrap: wrap ;
    min-height: initial ;
    
  }
  .drop-container{
    padding-bottom: 0 ;
    width: 100% ;
  }
}

.toggle-wrapper{
  float: right ;
  
}
.drag-drop-toggle{
  position: absolute ;
  right: 10px;
  top: 0 ;
}

.page_title{
  position: relative;
}

.switch {
  position: relative;
  display: inline-block;
    width: 45px;
    height: 22px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50% ;
}
.label-inline{
    display: inline !important;
    float: left;
    width: initial;
    padding: 0 10px;
    line-height: 20px;
}
input:checked + .slider {
  background-color: #00703C;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.drag-disabled .drag-icon-controller{
  display: none !important ;
  visibility: hidden !important ;
  opacity:  0 !important;
}

/* April 2024 */
.dashboard_expiration_date_no_data{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
}