/** Styles for no interview form*/
.no_int_form_container {
  .text_container {
    background-color: #eef0f2;
    padding: 15px;
  }
}
/**Loader Styles **/
.loader_container {
  width: 100%;
    height: 100%;
    z-index: 9999;
    position: absolute;
  .overlay_loader {
    display: table;
    background: transparent;
    position: absolute;
    top: 20rem;
    left: 50%;
    i {
      display: block;
      text-align: center;
      font-size: 6rem;
    }
  }
}

/** Styles for Interview Tab */
.int_tab_container {
  .int_tab_header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .add_int_btn {
      margin-top: 15px;
    }
    .back_int_btn {
      margin-top: 15px;
      float: right;
    }
  }
  .int_form_container {
    padding: 15px;
  }
  @media screen and (max-width: 462px) {
    .int_tab_header {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }
}

/** Styles for Interview form List */
.int_list_container {
  .int_list_tbl {
    table {
      width: 100%;
      th {
        padding: 12px;
        cursor: pointer;
        text-align: left;
        background-color: #eef0f2;
        border-bottom: 1px solid #d8d8d8;
        .header_txt {
          display: flex;
          align-items: center;
          flex-direction: row;
        }
      }
      tr {
        background-color: #fbfbfb;
        &:nth-child(2n + 1) {
          background-color: #f3f3f3;
        }
        .pagination_row {
          padding: 0px !important;
          border: none;
          color: #fff;
          background: #fff;
        }
        td {
          padding: 12px;
          border-bottom: 1px solid #d8d8d8;
        }
        .icon_container {
          display: flex;
          gap: 22px;
          .icons_style {
            width: 24px;
          }
        }
      }
    }
    .pagination {
      display: inline-block;
      padding-left: 0;
      margin: 10px 0 2px;
      border-radius: 4px;
    }
  }
}

//new interview form
.interview_form_wrapper {
  .interview_form_body {
    padding: 32px 33px;
    border: 1px solid #d8d8d8;
    @media screen and (max-width: 480px) {
      padding: 25px 20px;
    }
  }
  .error {
    border: 1px solid red !important;
  }
  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    line-height: 1.333;
    margin: 0;
    font-weight: 700;
    margin-bottom: 32px;
  }
  .form__wrapper {
    .form_group_row {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 640px) {
        flex-wrap: wrap;
      }
      .form_group {
        width: calc(50% - 12px);
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }
    }
    .form_group {
      margin-bottom: 24px;
      &.overall_rating {
        border: none;
        .overall_rating_wrapper {
          border: 1px solid #d8d8d8;
          box-sizing: border-box;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          > p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #202020;
            margin-bottom: -27px;
            &:nth-last-child(1) {
              @media screen and (max-width: 480px) {
                transform: rotate(90deg);
              }
            }
            @media screen and (max-width: 480px) {
              transform: rotate(-90deg);
            }
          }
          .radio_btn_wrapper {
            display: flex;
            justify-content: center;
            padding: 40px 8px 56px;
            @media screen and (max-width: 480px) {
              padding: 30px 2px 46px;
            }
            .radio_group {
              display: flex;
              flex-direction: column;
              margin: 0 40px;
              min-width: 20px;
              align-items: center;
              cursor: pointer;
              @media screen and (max-width: 991px) {
                margin: 0 30px;
              }
              @media screen and (max-width: 640px) {
                margin: 0 15px;
              }

              @media screen and (max-width: 480px) {
                margin: 0 6px;
              }
              input {
                order: 2;
                cursor: pointer;
                min-height: 20px;
                margin-top: 24px;
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0;
                width: 100%;
                height: 100%;
                &:checked ~ .radiobtn {
                  &:after {
                    opacity: 1;
                  }
                }
                &:disabled {
                  background-color: #d0d3d4;
                }
              }
              label {
                text-align: center;
                margin-bottom: 0;
                min-height: 64px;
                span.radiobtn {
                  position: absolute;
                  top: 16px;
                  left: -3px;
                  height: 25px;
                  width: 25px;
                  border-radius: 50%;
                  border: 2px solid #9d9d9d;
                  cursor: pointer;
                  pointer-events: none;
                  margin-top: 24px;
                  margin-bottom: -3px;
                  &:after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-47%, -52%);
                    background-color: #3f7e39;
                    border-radius: 9999px;
                    opacity: 0;
                    pointer-events: none;
                    cursor: pointer;
                    // transition: all 0.3s ease-in-out;
                  }
                }
              }
              .container_radio {
                position: relative;
              }
            }
          }
        }
      }
      .table_wrapper {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        overflow-x: auto;
        table,
        th,
        td {
          border: 1px solid #d8d8d8;
        }
        table {
          width: 100%;
          min-width: 947px;
          thead,
          tr {
            @media screen and (max-width: 640px) {
              display: flex;
              align-items: center;
            }
          }
          thead th,
          tbody td {
            padding: 17.5px 16px;
            width: 100px;
            max-width: 100px;
            text-align: center;
            position: relative;
            &:nth-child(1) {
              width: 540px;
              max-width: 540px;
              text-align: left !important;
            }
            .radio_group {
              display: flex;
              flex-direction: column;
              // margin: 0 40px;
              min-width: 20px;
              align-items: center;
              cursor: pointer;
              input {
                order: 2;
                cursor: pointer;
                min-height: 20px;
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0;
                width: 100%;
                height: 100%;
                &:checked ~ .radiobtn {
                  &:after {
                    opacity: 1;
                  }
                }
              }
              label {
                text-align: center;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: static !important;
                width: 100%;
                height: 100%;
                span.radiobtn {
                  position: relative;
                  height: 25px;
                  width: 25px;
                  border-radius: 50%;
                  border: 2px solid #9d9d9d;
                  cursor: pointer;
                  pointer-events: none;
                  margin-top: -1px;
                  &:after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-53%, -52%);
                    background-color: #3f7e39;
                    border-radius: 50%;
                    opacity: 0;
                    pointer-events: none;
                    cursor: pointer;
                    // transition: all 0.3s ease-in-out;
                  }
                }
              }
              .container_radio {
                position: relative;
              }
            }
          }
          thead th {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #202020;
            font-family: "proxima_nova_altbold", Georgia, sans-serif !important;
            padding: 9.5px 16px;
            &:nth-first-child() {
              text-align: left;
            }
            span {
              width: 100%;
              display: block;
            }
          }
          tbody td {
            font-family: "proxima-nova", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #202020;
            b {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #202020;
              font-family: "proxima-nova Semibold", Georgia, sans-serif;
            }
            strong {
              font-size: 14px;
            }
            // &:nth-child(4), &:nth-child(6){
            //   .radio_group{
            //     label{
            //       span.radiobtn{
            //         &:after{
            //           transform: translate(-52%, -52%);
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
      }
      label {
        margin-bottom: 10px;
        font-family: "proxima-nova", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.333;
        margin-top: -15px;
      }
      input,
      textarea {
        min-height: 40px;
        padding: 10px 16px;
        font-family: "proxima-nova", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.333;
        margin-bottom: 0;
        display: block;
        &:disabled {
          background-color: #d0d3d4;
        }
      }
      &.notes {
        margin-top: 56px;
        margin-bottom: 56px;
      }
      .date_picker {
        width: 100%;
      }
    }
    .grouped_inputs {
      margin-top: 56px;
      &.m_lg {
        > .vertical_group {
          > .radio_btn_wrapper {
            > .radio_group {
              + .radio_group {
                margin-top: 32px;
              }
            }
          }
        }
      }
      &.living_status {
        .vertical_group .vertical_group {
          .radio_btn_wrapper {
            .radio_group {
              + .radio_group {
                margin-top: 24px !important;
              }
            }
          }
        }
      }
      &.sm_check {
        .form_group.notes {
          margin-top: 0;
        }
      }
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #202020;
        margin: 0;
        margin-bottom: 16px;
        font-family: "proxima-nova Semibold", Georgia, sans-serif;
      }
      .vertical_group {
        margin-top: 25px;
      }
    }
  }
  .form_group_row.vertical_group {
    .vertical_group {
      .radio_btn_wrapper .radio_group {
        margin-bottom: 12px;
        min-height: unset;
      }
    }
    .radio_btn_wrapper {
      display: block;
      width: 100%;
      .radio_group {
        display: flex;
        cursor: pointer;
        // margin-bottom: 23px;
        margin-bottom: 12px;
        min-height: 40px;
        align-items: center;
        @media screen and (max-width: 640px) {
          margin-bottom: 20px;
          min-height: unset;
        }
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        input.radio_inp {
          cursor: pointer;
          min-height: 20px;
          min-width: 20px;
          position: absolute;
          opacity: 0;
          margin: 0;
          cursor: pointer;
          left: 0;
          width: auto;
          height: auto;
          &:checked ~ .radiobtn {
            &:after {
              opacity: 1;
            }
          }
          &:checked ~ .form_group {
            input {
              pointer-events: all;
            }
          }
        }
        label {
          text-align: left;
          margin-bottom: 0;
          display: flex;
          float: none;
          position: relative;
          align-items: center;
          flex-wrap: wrap;
          input {
            margin-top: 0 !important;
          }
          p,
          p strong {
            cursor: pointer;
            margin-bottom: 0;
            margin-left: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #202020;
            font-family: "proxima-nova", sans-serif;
            strong {
              margin-left: 0;
              font-weight: 600;
              font-family: "proxima-nova Semibold", Georgia, sans-serif;
            }
          }
          span.radiobtn {
            position: relative;
            top: 0px;
            left: 0px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid #9d9d9d;
            cursor: pointer;
            pointer-events: none;
            &:after {
              content: "";
              position: absolute;
              width: 13px;
              height: 13px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: #3f7e39;
              border-radius: 9999px;
              opacity: 0;
              pointer-events: none;
              cursor: pointer;
              // transition: all 0.3s ease-in-out;
            }
          }
          .form_group_row {
            width: 100%;
          }
        }
        .container_radio {
          position: relative;
        }
        .form_group_row {
          margin-left: 28px;
          margin-top: 16px;
        }
        .form_group {
          margin-bottom: 0;
          margin-left: 16px;
          @media screen and (max-width: 640px) {
            margin-top: 10px;
            margin-left: 30px;
          }
          input {
            width: 160px;
            pointer-events: none;
          }

          input[type="date"]::-webkit-inner-spin-button,
          input[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
          }
          label.hidden_label {
            display: none !important;
          }
        }
      }
    }
  }

  .submit_action__wrapper {
    border: 1px solid #d8d8d8;
    border-top: none;
    padding: 32px 36px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 640px) {
     flex-wrap: wrap;
    }
    .input__wrapper {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 640px) {
        width: 100%;
        margin-bottom: 15px;
        justify-content: center;
       }
      input {
        margin-left: 16px;
        margin-bottom: 0;
        width: auto;
        color: #fff;
        padding: 8px 24px;
        background: #3f7e39;
        text-transform: capitalize;
        font-family: "proxima_nova_altbold", Georgia, sans-serif;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        @media screen and (max-width: 640px) {
          margin-right: unset !important;
          margin-left: unset !important;
          margin:0px 5px !important;
         }
      }
    }
    .no_margin_input{
      input {
        margin-right: 16px;
        margin-left: 0px !important;
        @media screen and (max-width: 640px) {
          margin-right: 0px !important;
         }
      }
    }
  }
}

//success message
.success_msg__wrapper {
  .success_cntnt__wrapper {
    // padding: 50px;
    // max-width: 450px;
    // margin: 0 auto;
    padding: 45px;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 50vh;
    img {
      max-width: 48px;
      max-height: 48px;
      margin: 0 auto;
      display: block;
      margin-bottom: 16px;
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #202020;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 20px;
    }
    .input__wrapper {
      text-align: center;
      a.button {
        margin: 0 auto;
        margin-bottom: 0;
        width: auto;
        color: #fff;
        padding: 7.5px 20px;
        background: #3f7e39;
        //text-transform: capitalize;
        font-family: "proxima_nova_altbold", Georgia, sans-serif;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        min-height: 32px;
      }
    }
  }
}
