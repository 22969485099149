.tooltip_root {
  position: relative;
}

/* Styles for tooltip positioned at the top */
.custom_tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff !important;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -50px; /* Adjust this value to position the tooltip */
}

.custom_tooltip::after {
  border: solid 5px #171717;
  content: "";
  position: absolute;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -10px;
  left: 5px;
}

/* Styles for tooltip positioned at the bottom */
.custom_tooltip.bottom {
  visibility: hidden;
  background-color: black;
  color: #fff !important;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 10px;
}

.custom_tooltip.bottom::after {
  content: "";
  position: absolute;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent; /* Adjust to make arrow point upwards */
  left: 5px;
}

.custom_tooltip_children {
  cursor: inherit;
}

.custom_tooltip_children:hover .custom_tooltip,
.custom_tooltip_children:hover .custom_tooltip.bottom {
  visibility: visible;
}
