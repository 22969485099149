.widget_header {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header_action {
  min-width: 180px;
}

.header_action button {
  padding: 5px 15px;
}

.header_action_today {
  color: #000;
}

.header_action_today_active {
  color: #fff;
  background-color: #cac280;
}

.header_action_margin {
  margin-left: 10px;
  margin-right: 20px;
}

.header_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 100%;
  padding-left: 20px;
}

.content {
  margin-top: 30px;
  min-height: 30px;
  overflow: auto;
  height: calc(100vh - 365px);
}

.item_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid #eee;
  background-color: rgb(223 220 220);
}

.item_child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid #eee;

}

.item_head_company{
  flex: 1
}
.item_head_name{
  flex: 2
}
.item_head_day{
  flex: 1;
  text-align: right;
}

.no_records {
  display: flex;
  justify-content: center;
}

.header_first {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}

.print_btn_container {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

}

.print_btn_container .print_btn {
  height: 32px;
  padding: 4px 10px;
}

@media only screen and (max-width: 991px) {
  .print_btn_container {
    justify-content: flex-start;
    padding-top: 15px;
    width: 100%;
  }
}

@media only screen and (max-width: 602px) {
  .header {
    flex-direction: column;
    gap: 12px;
  }


  .print_btn_container .print_btn {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .print_btn_container {
    flex-wrap: wrap;
  }

  .print_btn_container button {
    margin-left: 0 !important;
  }

  .header_first {
    width: 100%;
  }

  .print_btn_container {
    width: 100%;

  }
}