.back_to_board {
  display: block;
  padding: 15px 20px;
  background: #f8fafb;
}
.back_to_board .back_button {
  position: relative;
  display: inline-block;
}
.back_to_board .back_button img {
  position: absolute;
}
.back_to_board .back_button span {
  display: block;
  line-height: 12px;
  padding-left: 15px;
  font-weight: 600;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.back_to_board .grid {
  width: 210px;
  display: inline-block;
  float: right;
  text-align: right;
}
.back_to_board .grid .list-view {
  display: inline-block;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
}
.back_to_board .grid .list-view img {
  width: 14px;
  position: absolute;
  top: 0;
}
/* .back_to_board .grid .list-view a{
  position: relative;
} */
.back_to_board .grid .list-view span {
  padding-left: 20px;
  font-weight: 600;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.back_to_board .grid .first img {
  top: 3px;
}
rect.bar {
  fill: #78a474 !important;
}
.graph_date_wrap {
  width: 100%;
  float: left;
}
.graph_date {
  width: 835px;
  float: right;
  display: flex;
  flex-wrap: wrap;
}
.print_button {
  display: inline-block;
  background: #00703c;
  color: #fff;
  padding: 10px 10px;
  height: 38px;
  width: 150px;
  text-align: center;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
}
.graph_date .form-group {
  position: relative;
  width: 150px;
  display: inline-block;
  margin-left: 2%;
}
.graph_date .form-control {
  position: relative;
  width: 150px;
  display: inline-block;
  margin-left: 2%;
  height: 40px;
}
.graph_date .form-group input {
  height: 40px;
  font-family: "proxima-nova", Georgia, sans-serif;
  font-size: 13px;
}
.graph_date .form-group .datepicker-image {
  right: 10px;
}
.list_item_head__image {
  max-width: 50px;
  display: inline-block;
  min-width: 50px !important;
}
.report-navi a {
  color: #000;
}
.back_button a {
  color: #000;
}
.y-axis {
  margin-top: 200px;
  position: absolute;
  left: -30px;
  font-weight: 700;
  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  float: left;
}
.x-axis {
  text-align: center;
  font-weight: 700;
}

.chart-report-wrapper {
  position: relative;
}
.graph_header {
  float: left;
}
.for_mobile_only {
  display: none;
}

.sort-arrow {
  cursor: pointer;
  width: 0px;
  display: inline-block;
  height: 0px;
  margin: 2px 5px;
  border-style: solid;
  line-height: 16px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #000000 transparent;
  opacity: 0.2;
}
.sort-arrow.desc {
  opacity: 1;
  /*border-width: 5px 5px 0 5px;*/
  border-width: 0 5px 5px 5px;
  /*border-color: #000000 transparent transparent transparent;*/
  border-color: transparent transparent #000000 transparent;
}

.sort-arrow.asc {
  opacity: 1;
  /*border-width: 0 5px 5px 5px;*/
  border-width: 5px 5px 0 5px;
  /*border-color: transparent transparent #000000 transparent;*/
  border-color: #000000 transparent transparent transparent;
}




.dropdown-container{
  position: relative;
}

/** 3 dots style **/
.dropdown-container .three-dots:after {
  cursor: pointer;
  color: #3f7e39;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}

.dropdown-container .dropdown {
  position: absolute;
  right: 6px;
  bottom: 29px;
  background-color: #fff;
  box-shadow: 0 0 3px 3px #e7e7e7;
  outline: none;
  border-radius: 6px;
  border: 1px solid #f6f6f6;
  padding: 5px;

}

.reset_btn_container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.reset_btn_container .btn__reset {
  font-size: 12px;
  min-width: 140px;
  background: #fff;
  color: #3f7e39 !important;
}

.inp_sub_container{
  display: flex;
}

.inp_sub_container input{
  margin-bottom: 0;
}

@media screen and (max-width: 1250px) {
  .graph_date {
    width: 100%;
    margin-bottom: 15px;
  }
  .graph_date .form-group {
    margin-left: 0px;
    margin-right: 1%;
    margin-bottom: 0px;
  }
  .graph_date .form-control {
    margin-left: 0px;
    margin-right: 1%;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .graph_date {
    width: 100%;
    position: relative;
    right: inherit;
  }
  .for_mobile_only {
    display: block;
  }
  .graph_date .form-group {
    margin-left: 0px;
    margin-right: 1%;
  }
  .graph_date .react-datepicker-wrapper {
    width: 100%;
  }
  .graph_date .react-datepicker__input-container {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .graph_date .form-group {
    margin-left: 0px;
    margin-right: 5%;
    width: 45%;
  }
  .graph_date .form-control {
    width: 45%;
    margin-right: 5%;
  }
  .print_button {
    margin-top: 15px;
  }
}
@media screen and (max-width: 440px) {
  .back_to_board .grid {
    float: none !important;
  }
  .back_to_board .back_button {
    margin-bottom: 15px;
  }
}
.top-buttons-wrapper {
  width: 280px !important;
}

/* Report List Table Styles */
.report_list_width_full {
  width: 100%;
  word-wrap: break-word;
}

@media screen and (min-width: 1229px) {
  .report_list_width_full th {
    padding: 12px !important;
    cursor: pointer;
  }
  .report_list_table_tbody td {
    padding: 12px !important;
    /* max-width: 120px; */
  }
  .qualification_report .report_list_table_tbody td {
    padding: 12px !important;
    max-width: fit-content !important;
  }
  .report_list_width_full.long__report th {
    padding: 10px !important;
    cursor: pointer;
  }
  .report_list_table_tbody.long__report td {
    padding: 10px !important;
    max-width: 120px;
  }
}

@media screen and (max-width: 1228px) and (min-width: 600px) {
  .report_list_width_full th {
    padding: 8px 2px !important;
    cursor: pointer;
  }
  .report_list_table_tbody td {
    padding: 8px 8px !important;
    max-width: 120px;
  }
  .qualification_report .report_list_table_tbody td {
    padding: 8px 8px !important;
    max-width: fit-content !important;
  }
}

@media screen and (max-width: 1080px) {
  .tab_hide {
    display: none;
  }
  .tab_show {
    display: block;
  }
}
@media screen and (min-width: 1081px) {
  .tab_hide {
    display: inline-table;
  }
  .tab_show {
    display: none;
  }
}

.report_list_table_row {
  background: #f6f6f6;
  border: 1px solid #eef0f2;
}

.report_list_table_tbody tr {
  border: 1px solid #eef0f2;
}

.report_list_table_tbody a {
  color: #000;
}

.text_align_center {
  text-align: center;
}

@media screen and (min-width: 621px) {
  .report_tbl_head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: flex-start; */
    margin-bottom: 15px;
  }

  .report_head_btn_grp {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
}

.print-export-btn{
  display: flex;
  gap:10px;
}

.print-export-btn-inventory{
  display: flex;
  gap:10px;
}

@media screen and (max-width: 425px){
  .print-export-btn{
    min-width: 300px;
  }

  .print-export-btn-inventory{
    min-width: 200px;
    display: block;
  }
}

@media screen and (max-width: 320px){
  .print-export-btn{
    min-width: 200px;
  }
  .inp_sub_container{
    min-width: 200px !important
  }
}

@media screen and (max-width: 768px){
  .print-export-btn{
    max-width: 300px;
    flex-wrap: wrap;
  }
}



@media screen and (max-width: 620px) {
  .report_tbl_head {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
    flex-direction: column;
  }
}

@media screen and (max-width: 620px) {
  .report_head_btn_grp {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
}

.report_list_table_row .report_table_header {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* text-align: center; */
}

@media screen and (max-width: 1024px) {
  .exp-list .mobile_hide {
    display: none;
    
  }
}

.expiry_report .report_list_table_row .report_table_header span {
  font-size: 1.1rem;
  width: auto;
}


@media screen and (max-width: 1024px){
.exp-mob-list.for_mobile_history_list {
    display: block;
}
}
.date_picker_cust {
  width: 100%;
  height: 36px;
  padding: 0 10px;
  margin: 0 0 12px;
  border: 1px solid #d0d3d4;
  font: 400 14px/15px "Proxima Nova", Georgia, sans-serif;
  color: #9c9c9c;
  box-sizing: border-box;
  border-radius: 3px;
}

@media screen and (max-width: 620px) {
  .report_multi_btn_grp {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  /* .report_multi_btn_grp div:first-child{
    width: 100%;
  } */
  .report_multi_btn_grp .inp_sub_container {
    width: 100%;
  }
}

@media screen and (max-width: 932px) {
  .report_multi_btn_grp.exp_multi_btn_flx {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .report_multi_btn_grp.exp_multi_btn_flx .inp_sub_container {
    width: 100%;
  }
  .report_head_btn_grp.exp_multi_head_flx {
    width: 100%;
  }
  .report_multi_btn_grp.exp_multi_btn_flx .inp_sub_container .select__control {
    width: 100%;
  }
}

@media screen and (min-width: 621px) {
  .report_multi_btn_grp {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
  .report_multi_btn_grp .react-datepicker__input-container .date_picker_cust {
    margin: 0 !important;
  }
  .report_multi_btn_grp .inp_sub_container {
    min-width: 200px;
  }
  .report_multi_btn_grp .inp_sub_container.date_filter_container {
    min-width: 172px;
  }
  .report_multi_btn_grp.exp_multi_btn_flx {
    flex-wrap: wrap;
  }

}

@media screen and (min-width: 769px) {
  .report_multi_btn_grp .inp_sub_container.date_filter_container {
    min-width: 172px;
  }
  
}

.report_multi_btn_grp button:disabled {
  background-color: #747474;
}

@media screen and (max-width: 1160px) {
  .report_tbl_head.sold_rpt_mbl_flx {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .report_tbl_head.sold_rpt_mbl_flx
    .report_head_btn_grp
    .report_multi_btn_grp
    .inp_sub_container {
    width: auto;
  }
  /* .report_tbl_head.sold_rpt_mbl_flx .report_head_btn_grp button {
    width: 100%;
  } */
}

.report_table_container .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 10px 0 0;
  border-radius: 4px;
}

.report_table_container .print_button {
  padding: 4px 10px;
  height: 36px;
}

@media screen and (max-width: 620px) {
  .report_tbl_head .report_head_btn_grp .print_button {
    width: 100%;
  }
}

.report_table_container .inner_container {
  box-shadow: unset;
}

@media screen and (max-width: 320px){
 .select__control{
  width: 200px !important
 }
  
}


.report_tbl_head_vehicle{
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  /* align-items: flex-start; */
  margin-bottom: 15px;
}

@media screen and (max-width: 425px){
  .report_tbl_head_vehicle{
    display: block;
  }
   
 }