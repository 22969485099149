.report_container {
  padding: 60px 0px;
  .page_title {
    position: relative;
    border-bottom: 1px solid #d8d8d8;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-family: "proxima_nova_altbold", Georgia, sans-serif;
  }
  .p_custom {
    a {
      padding: 1rem 1rem !important;
      font-size: 1.2rem !important;
      min-width: 172px !important;
    }
    li {
      &:first-child {
        a {
          padding: 1rem 1rem !important;
        }
      }
    }
  }
}

.no_access_container {
  text-align: center;
  padding: 12rem;
}
